import styled from 'styled-components';

export const FileArticleContainer = styled.div`
  width: 100%;
  height: fit-content;
  padding-top: ${props => props.hasTitle ? 100 : 60}px;
  padding-bottom: ${props => props.hasTitle ? 30 : 100}px;
  background-color: ${props => props.theme.colors.middleLightGray};
  @media ${props => props.theme.device.tablet} {
    padding-top: 40px;
    padding-bottom: 30px;
  }
`;

export const FileArticleHeader = styled.div`
  display: flex;

  @media ${props => props.theme.device.tablet} {
    flex-direction: column;
    margin-bottom: 35px;
  }
  
  @media ${props => props.theme.device.mobile} {
    padding: 0 20px;
    margin-bottom: 0;
  }
`;

export const Title = styled.h2`
  max-width: 100%;
  margin-bottom: 60px;

  @media ${props => props.theme.device.tablet} {
    margin: 20px 0;
  }
`;
