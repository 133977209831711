import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'ui/atoms';
import { withMediaQueries } from 'hoc/withMediaQueries';
import { connect } from 'react-redux';
import { menuRefs } from 'utils/menu';

import {
  FinalSliderSettings,
  SliderSettings
} from '../../../utils/slider-settings';
import CustomCarousel from '../CustomCarousel';
import {
  SliderContainer,
  CustomContainer,
  Card,
  Image,
  TextWrapper,
  Title,
  Subtitle,
  ButtonWrapper,
  TextBoxWrapper,
  TextBoxImg,
  CtasWrapper
} from './style';
import { HtmlWrapper } from '../RenderLandingParagraph/style';
import OpenSans from '../../typography/openSans';
import { HtmlRaw } from '../index';

function Slider({
  cards,
  onClick,
  mediaIsPhone,
  isLastSlider,
  scrollToRef,
  scrollCount,
  customMediaQueries
}) {
  const myRef = useRef(null);

  useEffect(() => {
    if (scrollToRef === menuRefs.INITIATIVES) {
      myRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [scrollToRef, scrollCount]);

  const htmlDecode = (input) => {
    const doc = new DOMParser().parseFromString(input, 'text/html');
    return doc.documentElement.textContent;
  };

  const getCustomFontSize = () => {
    if (mediaIsPhone) return { fontSize: 20 }
    if (customMediaQueries) {
      let config = {}
      Object.entries(customMediaQueries).forEach(([label, value]) => {
        if (value) {
          switch(label) {
            case 'custom__1024':
              config = { fontSize: 25 };
              break;
            case 'custom__1067':
              config = { fontSize: 28 };
              break;
            case 'custom__1141':
              config = { fontSize: 30 };
              break;
            case 'custom__1214':
              config = { fontSize: 32 };
              break;
            case 'custom__1324':
              config = { fontSize: 35 };
              break;
            case 'custom__1434':
              config = { fontSize: 38 };
              break;
            default:
              config = { fontSize: 40 };
          }
        }
      })
      return config;
    }
  }

  return (
    <SliderContainer isLastSlider={isLastSlider} ref={myRef}>
      <CustomCarousel
        config={isLastSlider ? FinalSliderSettings : SliderSettings}
      >
        {cards.map((c, index) => (
          <Card isLastSlider={isLastSlider} key={`card-${index}`} img={c.image}>
            <CustomContainer isLastSlider={isLastSlider} pullUp={c.logo && c.logo.length > 0}>
              {isLastSlider ? (
                <TextBoxWrapper>
                  <TextBoxImg>
                    <Title isLastSlider={isLastSlider}>
                      <OpenSans
                        type="landingtitle"
                        configuration={
                          mediaIsPhone ? { fontSize: 25 } : {}
                        }
                      >
                        {c.title.replace(/&quot;/g, '"')}
                      </OpenSans>
                    </Title>
                    <OpenSans type="bodyLanding" configuration={mediaIsPhone ? { fontSize: 16 } : { fontSize: 16 }}>
                      <HtmlWrapper
                        dangerouslySetInnerHTML={{
                          __html: c.subtitle
                        }}
                      />
                    </OpenSans>
                    {c?.cta_1_url && (
                      <CtasWrapper>
                        <ButtonWrapper isLastSlider={isLastSlider}>
                          {c.cta_1_type === 'apple' ? (
                            <Button
                              name="download"
                              text="Scarica"
                              onClick={() => onClick(c?.cta_1_url)}
                              target="_blank"
                              type="STORE"
                              isDownload
                              mediaIsPhone={mediaIsPhone}
                            />
                          ) : (
                            <Button
                              name={c?.cta_1_title}
                              text={'Scopri di più'.toUpperCase()}
                              onClick={() => onClick(c?.cta_1_url)}
                              height={mediaIsPhone ? 35 : 55}
                              type="PRIMARY"
                              mediaIsPhone={mediaIsPhone}
                            />
                          )}
                        </ButtonWrapper>
                      </CtasWrapper>
                    )}
                  </TextBoxImg>
                </TextBoxWrapper>
              ) : (
                <>
                  {c.logo && c.logo.length > 0 ? (
                    <Image
                      src={c.logo}
                      mediaIsPhone={mediaIsPhone}
                      pushDown={mediaIsPhone ? null : (c.cta_1_url && c.cta_1_url.length) ? 3 : 5}
                    />
                  ) : null}
                  <TextWrapper hasCTA={Boolean(c.cta_1_url)}>
                    <Title>
                      <OpenSans
                        type="sliderTitle"
                        // configuration={mediaIsPhone ? { fontSize: 20 } : {}}
                        configuration={customMediaQueries ? getCustomFontSize() : {}}
                      >
                        {c.title}
                      </OpenSans>
                    </Title>
                    <Subtitle>
                      <OpenSans
                        type="sliderSubtitle"
                        configuration={mediaIsPhone ? { fontSize: 14 } : {}}
                      >
                        <HtmlRaw html={htmlDecode(c.subtitle)} />
                      </OpenSans>
                    </Subtitle>
                  </TextWrapper>
                  {c.cta_1_url && (
                    <ButtonWrapper>
                      <Button
                        text="Scopri di più"
                        type={Button.TYPE.PRIMARY}
                        onClick={() => onClick(c.cta_1_url)}
                        height={mediaIsPhone ? 35 : 55}
                        mediaIsPhone={mediaIsPhone}
                      />
                    </ButtonWrapper>
                  )}
                </>
              )}
            </CustomContainer>
          </Card>
        ))}
      </CustomCarousel>

    </SliderContainer>
  );
}

Slider.propTypes = {
  cards: PropTypes.array,
  onClick: PropTypes.func,
  mediaIsPhone: PropTypes.bool,
  isLastSlider: PropTypes.bool,
  scrollToRef: PropTypes.string,
  scrollCount: PropTypes.number,
  customMediaQueries: PropTypes.object
};

const customMediaQueries = {
  custom__1024: [1024, 1067],
  custom__1067: [1067, 1141],
  custom__1141: [1141, 1214],
  custom__1214: [1214, 1324],
  custom__1324: [1324, 1434],
  custom__1434: [1434, 1507]
}

export default connect(state => {
  const { scrollToRef, scrollCount } = state.landingPage;
  return { scrollToRef, scrollCount };
})(withMediaQueries(Slider, customMediaQueries));
