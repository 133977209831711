import React, { useEffect, useState } from 'react';
import { CardBody, CardContainer, CardInfo, CardTitle, ButtonWrapper } from './style';
import OpenSans from '../../../typography/openSans';
import HtmlRaw from '../../HtmlRaw';
import { Button } from '../../../atoms';

const DesktopFileArticleCard = ({ data }) => {
  const [info, setInfo] = useState(data);
  useEffect(() => {
    setInfo(data);
  }, [data]);
  return (
    <CardContainer background={info.image} position={info.textbox_position} imgPosition={info.image_position}>
      <CardInfo>
        <CardTitle><OpenSans type="semibold" configuration={{ fontSize: 25 }}>{info.title}</OpenSans></CardTitle>
        <CardBody>
          <OpenSans configuration={{fontSize: 16, lineHeight: 1.5}}>
            <HtmlRaw
            html={info.body_trimmed || info.body || info.body_2_trimmed || info.body_2 || info.description}
            withEllipsis={info.body_trimmed !== info.body}
          /></OpenSans>
        </CardBody>
        <ButtonWrapper>
          <Button
            text={info.cta_1_title.toLowerCase() || info.cta_2_title.toLowerCase() || 'Scopri di più'.toUpperCase()}
            type={info.cta_1_type || info.cta_2_type || Button.TYPE.PRIMARY}
            onClick={() => window.open(info.cta_1_url || info.cta_2_url, '_blank')}
          />
        </ButtonWrapper>
      </CardInfo>
    </CardContainer>
  );
};

DesktopFileArticleCard.propTypes = {};

export default DesktopFileArticleCard;
