import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { menuRefs } from 'utils/menu';
import { Container } from 'ui/gridSystem';
import { withMediaQueries } from 'hoc/withMediaQueries';

import {
  TrainingOfferContainer, Title, CardsContainer, CardWrapper,
  Card, BoxBody, Link, Label, IconPlus, InfoContainer,
  IconClose, IconLine, LinkText, LinkIcon
} from './style';
import OpenSans from '../../typography/openSans';
import { HtmlWrapper } from '../RenderLandingParagraph/style';

function TrainingOffer2({
  mediaIsPhone,
  cards,
  onClick,
  scrollToRef,
  scrollCount
}) {
  const myRef = useRef(null);
  const [cardFocus, setCardFocus] = useState('');

  useEffect(() => {
    if (scrollToRef === menuRefs.TRAINING_OFFER) {
      myRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [scrollToRef, scrollCount]);

  return (
    <TrainingOfferContainer ref={myRef}>
      <Container>
        <Title>
          <OpenSans
            type="paragraphTitle"
            configuration={mediaIsPhone ? { fontSize: 20 } : {}}
          >
            Offerta formativa
          </OpenSans>
        </Title>
        <CardsContainer>
          {cards.map((c, index) => (
            <CardWrapper key={`card-${index}`}>
              <Card
                onTouchStart={() => setCardFocus(`card-${index}`)}
                onMouseEnter={() => setCardFocus(`card-${index}`)}
                onMouseLeave={() => setCardFocus('')}
                onTouchMove={() => setCardFocus('')}
                img={c.image}
                index={index}
              >
                <React.Fragment>
                  <Label>
                    <OpenSans
                      type="progressCardTitle2"
                      configuration={mediaIsPhone ? { fontSize: 16 } : {}}
                    >
                      {c.label_title}
                    </OpenSans>
                  </Label>
                  <IconPlus className="icon-plus" />
                  {
                    cardFocus === `card-${index}`
                    && (
                    <Link onClick={() => onClick(c.cta_1_url)}>
                      <LinkText>Scopri di più</LinkText>
                      <LinkIcon className="icon-arrow-right" />
                    </Link>
)
                  }
                </React.Fragment>
                <InfoContainer hasFocus={cardFocus === `card-${index}`}>
                  <IconClose className="icon-close-x" onClick={() => setCardFocus('')} />
                  <Label>
                    <OpenSans
                      className="animated-underline"
                      type="progressCardTitle2"
                      configuration={mediaIsPhone ? { fontSize: 16 } : {}}
                    >
                      {c.label_title}
                    </OpenSans>
                  </Label>
                  <IconLine />
                  <BoxBody>
                    <OpenSans
                      type="infoBoxBody2"
                      configuration={mediaIsPhone ? { fontSize: 14 } : {}}
                    >
                      <HtmlWrapper
                        color='#FFFFFF'
                        dangerouslySetInnerHTML={{ __html: c.body }}
                      />
                    </OpenSans>
                  </BoxBody>
                </InfoContainer>
              </Card>
            </CardWrapper>
          ))}
        </CardsContainer>
      </Container>
    </TrainingOfferContainer>
  );
}

TrainingOffer2.propTypes = {
  mediaIsPhone: PropTypes.bool,
  cards: PropTypes.array,
  onClick: PropTypes.func,
  scrollToRef: PropTypes.string,
  scrollCount: PropTypes.number
};

export default connect(state => {
  const { scrollToRef, scrollCount } = state.landingPage;
  return { scrollToRef, scrollCount };
})(withMediaQueries(TrainingOffer2));
