import styled, { css } from 'styled-components';
import { NavBarHeightMobile } from '../../../../const';

export const FixedContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  overflow: hidden;
  background: ${props => props.theme.colors.primary.base};
  z-index: 999;
`;

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: ${NavBarHeightMobile}px;
  width: 100%;
  position: relative;
  padding: 0 10px;
`;

export const LogoContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;

export const Logo = styled.img`
  width: 110px;
  height: auto;

  ${props =>
    props.sideMenu &&
    css`
      position: absolute;
      width: 101px;
      bottom: 30px;
      right: 22px;
    `}
`;

export const Award = styled.img`
  width: auto;
  height: 65%;
`;

export const BurgerButton = styled.div`
  position: absolute;
  top: 10px;
  color: ${props => props.theme.colors.white};
  right: 30px;
  z-index: 2;
`;

export const BurgerMenuContainer = styled.div`
  // &::before {
  //   content: '';
  //   display: block;
  //   height: ${NavBarHeightMobile}px;
  //   width: 100vw;
  //   position: absolute;
  //   top: 0;
  //   left: calc(50% - 8px);
  //   background-color: ${props => props.theme.colors.amber};
  //   clip-path: polygon(35px 0%, 100% 0, 100% 100%, 0% 100%);
  //   z-index: 0;
  // }
  //
  // &::after {
  //   content: '';
  //   display: block;
  //   height: ${NavBarHeightMobile}px;
  //   width: 100vw;
  //   position: absolute;
  //   top: 0;
  //   left: 50%;
  //   background-color: ${props => props.theme.colors.primary.base};
  //   clip-path: polygon(35px 0%, 100% 0, 100% 100%, 0% 100%);
  //   z-index: 1;
  // }
`;

export const SideMenu = styled.div`
  position: fixed;
  top: 0;
  right: -70vw;
  width: 70vw;
  height: 100vh;
  background-color: ${props => props.theme.colors.white};
  z-index: 1001;

  ${props =>
    props.isOpen &&
    css`
      right: 0;
    `}
  transition: all .2s;

  &::before {
    content: '';
    display: block;
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${props => props.theme.colors.primary.darker};
    clip-path: polygon(0% 0%, 100% 0, 100% 30%, 0% 100%);
    z-index: 0;
  }

  &::after {
    content: '';
    display: block;
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: -8px;
    left: 0;
    background-color: ${props => props.theme.colors.primary.base};
    clip-path: polygon(0% 0%, 100% 0, 100% 30%, 0% 100%);
    z-index: 1;
  }
`;

export const Mask = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 30vw;
  height: 100vh;
  z-index: 1001;
  background-color: rgba(0, 0, 0, 0.2);
  display: none;
  ${props =>
    props.isOpen &&
    css`
      display: block;
    `}
`;

export const IconClose = styled.div`
  position: absolute;
  top: 10px;
  right: 30px;
  font-size: 18px;
  z-index: 1002;
  color: ${props => props.theme.colors.white};
`;

export const MenuList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin-top: 63px;
`;

export const MenuItem = styled.li`
  cursor: pointer;
  &:not(:last-child) {
    margin-bottom: 30px;
  }
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.colors.white};
  z-index: 1002;
`;

export const Link = styled.a`
  &:link,
  :visited,
  :hover,
  :active {
    color: ${props => props.theme.colors.white};
  }
`;
