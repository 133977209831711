import produce from 'immer';
import { GET_DOMAIN, GET_FILTERED_DOMAIN_SETTINGS } from 'redux/actions/domain';

const initialState = {};

const domainReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_DOMAIN._SUCCESS: {
        draft = {
          ...state,
          ...action.response,
          brand: action.response.brand[0]
        };
        break;
      }
      case GET_FILTERED_DOMAIN_SETTINGS._SUCCESS: {
        const { settings } = action;
        draft = {
          ...state,
          domain_settings: settings
        };
        break;
      }
      default:
        return state;
    }
    return draft;
  });

export default domainReducer;
