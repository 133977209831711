import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactPlayerLoader from '@brightcove/react-player-loader';
import { BRIGHT_COVE_ACCOUNT } from 'api/config';
import { VideoPlaceholder, VideoWrapper } from './style';
import OpenSans from '../../typography/openSans';

const VideoPlayer = ({
  videoId,
  startFrom = '0',
  image
}) => {
  const [player, setPlayer] = useState();
  const [hasError, setHasError] = useState(false);

  const onSuccess = success => {
    const { player_ } = success.ref;
    player_.currentTime(startFrom);
    setPlayer(player_);
  };

  const onFailure = () => {
    setHasError(true);
  };

  const disposePlayer = () => {
    if (player && !player?.isDisposed_) {
      player.dispose();
    }
  };

  useEffect(() => {
    return () => {
      disposePlayer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [player]);

  return (
    <VideoWrapper>
      {!hasError || !videoId ? (
        <ReactPlayerLoader
          accountId={BRIGHT_COVE_ACCOUNT}
          videoId={videoId}
          onSuccess={onSuccess}
          onFailure={onFailure}
          startFrom={startFrom}
          poster={image}
        />
      ) : (
        <VideoPlaceholder>
          <OpenSans>Error loading video</OpenSans>
        </VideoPlaceholder>
      )}
    </VideoWrapper>
  );
};

VideoPlayer.propTypes = {
  videoId: PropTypes.string,
  startFrom: PropTypes.string,
  body: PropTypes.string,
  type: PropTypes.string,
  image: PropTypes.string
};

export default VideoPlayer;