import styled from 'styled-components';

export const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const Title = styled.div`
  margin-bottom: 14px;
  width: 460px;
  max-width: 100%;
  text-align: center;
`;

export const Subtitle = styled.div`
  width: 460px;
  max-width: 100%;
  margin-bottom: 35px;
  text-align: center;
`;

export const Icon = styled.span`
  position: absolute;
  top: 5%;
  font-size: 30px;
  right: 4%;
  cursor: pointer;
  color: ${props => props.theme.colors.primary.base};
`;

export const Image = styled.img`
  width: 162px;
  height: 135px;
  margin-bottom: 16px;
`;
