const FontBase = 16;
const NavBarHeight = 80;
const NavBarHeightMobile = 40;
const NavBarHeightTablet = 67;
const FooterHeight = 126;
const FooterHeightMobile = 214;
const MenuHeight = 77;
const MenuHeightLaptop = 86;
const MenuHeightTablet = 134;

export {
  FontBase,
  NavBarHeight,
  NavBarHeightTablet,
  NavBarHeightMobile,
  FooterHeight,
  FooterHeightMobile,
  MenuHeight,
  MenuHeightLaptop,
  MenuHeightTablet
};

export const CookiePolicyURL =
  'https://www.intesasanpaoloformazione.it/cookie-policy';
export const PrivacyPolicyURL =
  'https://www.intesasanpaoloformazione.it/privacy-policy';

export const Intesa = 'https://www.intesasanpaolo.com/';
export const IntesaTraining = 'https://www.intesasanpaoloformazione.it/';

export const CollectionPrice = '2.99';

export const MODES = {
  MANAGER: 'MANAGER',
  USER: 'USER'
};
