import styled from 'styled-components';
import { colorOpacity } from 'utils/common';

export const DigitalTrainingContainer = styled.div`
  width: 100%;
  min-height: 452px;
  height: fit-content;
  margin-top: 100px;
  overflow: hidden;
  @media ${props => props.theme.device.mobile} {
    margin-top: 0;
  }
`;

export const ContainerMain = styled.div`
  min-width: 100%;
  height: fit-content;
  min-height: 600px;
  background-size: cover;
  background-position: top left;
  position: relative;
  padding-bottom: 40px;
  @media ${props => props.theme.device.mobile} {
    min-width: 311px;
    max-width: 311px;
    margin: 0 auto;
  }
`;

export const BackgroundImg = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  min-height: 600px;
  background-image: url(${props => props.img});
  background-size: cover;
  background-position: top left;
  filter: blur(7px);
  @media ${props => props.theme.device.mobile} {
    max-width: 311px;
  }
`;

export const BodyContainer = styled.div`
  position: relative;
  width: 40%;
  margin-left: auto;
  padding-top: 73px;
  padding-right: 40px;

  @media ${props => props.theme.device.mobile} {
    width: 100%;
    padding: 281px 15px 0;
  }
`;
export const Logo = styled.div`
  width: 445px;
  height: 58px;
  position: relative;
  background-image: url(${props => props.img});
  background-size: contain;
  background-repeat: no-repeat;

  @media ${props => props.theme.device.mobile} {
    width: 222px;
    height: 29px;
    margin-bottom: 10px;
  }
`;
export const Title = styled.h2`
  margin-top: 17px;
  @media ${props => props.theme.device.mobile} {
    margin-top: 10px;
  }
`;
export const Body = styled.div`
  margin-top: 10px;
`;

export const ButtonWrapper = styled.div`
  width: 180px;
  height: 55px;
  margin-top: 25px;
  @media ${props => props.theme.device.mobile} {
    width: 125px;
    height: 35px;
    margin-top: 20px;
  }
`;

export const PlayBtnWrapper = styled.div`
  margin-top: 70px;
  display: flex;
  align-items: center;
  cursor: pointer;
  @media ${props => props.theme.device.mobile} {
    margin-top: 40px;
  }
`;

export const IconPlay = styled.div`
  width: 30px;
  height: 30px;
  font-size: 30px;
  color: ${props => props.theme.colors.primary.base};
  margin-right: 15px;

  @media ${props => props.theme.device.mobile} {
    width: 20px;
    height: 20px;
    font-size: 20px;
    margin-right: 10px;
  }
`;

export const Label = styled.div`
  position: absolute;
  top: 46px;
  left: -5px;
  background-color: ${props =>
    colorOpacity(props.theme.colors.primary.base, 0.7)};
  padding: 10px 20px;
  width: 309px;
  height: 70px;
  display: flex;
  align-items: center;
  @media ${props => props.theme.device.mobile} {
    width: 240px;
    height: 55px;
  }
`;

export const LabelImage = styled.div`
  background-image: url(${props => props.img});
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
  margin-right: 15px;
  @media ${props => props.theme.device.mobile} {
    width: 38px;
    height: 38px;
  }
`;

export const CarouselWrapper = styled.div`
  margin-top: 50px;
  position: relative;
  left: -${props => props.margin}px;
  width: 100vw;

  @media ${props => props.theme.device.mobile} {
    margin-top: 30px;
    left: calc(0px - (${props => props.windowWidth}px - 311px) / 2);
  }

  & div.slick-slide > div {
    max-width: fit-content;
    margin: 0 auto;
  }
`;

export const CollectionCard = styled.div`
  width: 300px !important;
  height: 350px;
  margin: 25px auto 20px;
  border-radius: 14px;
  overflow: hidden;
  /* box-shadow: 0 2px 4px 0 rgba(255, 255, 255, 0.74); */

  @media ${props => props.theme.device.mobile} {
    width: 281px !important;
    height: 328px;
    margin: 0 auto;
  }

`;

export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 14px;
  position: relative;
  top: 0;
  background-image: url(${props => props.img});
  background-size: cover;
  background-position: center center;
  background-color: #777;
  filter: ${props => (props.blur ? 'blur(7px)' : '')};
`;

export const CardTitle = styled.div`
  margin-top: 60%;
  margin-bottom: 10px;

  &.top {
    margin-top: 0;
  }
`;

export const CardContent = styled.div`
  width: 300px;
  min-height: 350px;
  bottom: 350px;
  border-radius: 14px;
  padding: 25px 15px 28px;
  position: relative;
  background: linear-gradient(
    ${props => colorOpacity(props.theme.colors.jet, 0.1)},
    ${props => colorOpacity(props.theme.colors.jet, 0.8)}
  );
  @media ${props => props.theme.device.mobile} {
    width: 281px;
    min-height: 328px;
    bottom: 328px;
  }
`;

export const CardBody = styled.div``;

export const CoursesContainer = styled.div`
  overflow: visible;
  & div:last-child {
    border: none;
  }
`;

export const CourseWrapper = styled.div`
  margin: 17px 0 0;
  padding-bottom: 10px;
  border-bottom: 1px solid
    ${props => colorOpacity(props.theme.colors.white, 0.34)};
`;

export const Duration = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
`;

export const IconPlaySmall = styled.div`
  width: 15px;
  height: 15px;
  font-size: 15px;
  margin-right: 10px;
  color: ${props => props.theme.colors.white};
`;
