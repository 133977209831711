import styled from 'styled-components';

export const NextArrow = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.primary.base};
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
  align-items: center;
  z-index: 100;
  &:hover {
    box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.15);
  }
`;
export const PrevArrow = styled(NextArrow)`
  transform: rotate(180deg);
`;

export const Icon = styled.div`
  width: 33px;
  font-size: 39px;
  line-height: 50px;
  color: ${props => props.theme.colors.primary.base};
`;

export const NextArrowWrapper = styled.div`
  max-width: fit-content;
  position: absolute;
  top: 154px;
  right: ${props => props.margin}px;

  &.disabled {
    opacity: 0.4;
  }
`;
export const PrevArrowWrapper = styled(NextArrowWrapper)`
  left: ${props => props.margin}px;
  z-index: 10;
`;
