import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { HRC } from './styles';

const HtmlRaw = ({ html, withEllipsis }) => {
  const setupRoutine = () => {
    // insert here all functions to fire
  };

  useEffect(() => {
    setupRoutine();
  }, []);

  return (
    <>
      {html && (
        <HRC
          dangerouslySetInnerHTML={{ __html: html.replace(/\u00A0/g, ' ') }}
          withEllipsis={withEllipsis}
        />
      )}
    </>
  );
};

HtmlRaw.propTypes = {
  html: PropTypes.string,
  withEllipsis: PropTypes.bool
};

export default HtmlRaw;
