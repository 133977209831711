import produce from 'immer';
import {
  GET_LANDING_PAGE,
  SCROLL_TO,
  TOGGLE_AUTOPLAY
} from 'redux/actions/landingPage';

const initialState = {
  data: {},
  autoPlay: true,
  scrollToRef: '',
  scrollCount: 0
};

const landingPageReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_LANDING_PAGE._SUCCESS: {
        const { response: arrayResponse } = action;
        const response = arrayResponse.reduce((acc, val) => {
          const formattedItem = {
            ...val,
            paragraphs: {
              data: val.paragraphs.map((item, index) => ({...item, order: index}))
            }
          };
          return [
            ...acc,
            formattedItem
          ]
        }, []);
        const formattedContacts = response[0].paragraphs.data.filter(data => data.type === 'Contacts');
        const formattedEvents = response[0].paragraphs.data.filter(data => data.type === 'Events');
        const fileArticleOrder = [...response[0].paragraphs.data.filter(data => data.type === 'File Article')][0]?.order;
        const paragraphs = [...response[0].paragraphs.data.filter(data => data.type !== 'Contacts' && data.type !== 'Events')];
        if(formattedEvents.length) paragraphs.push({type: 'Events', title: '', data: formattedEvents, order: formattedEvents[0].order, hasTitle: formattedEvents[0].order < fileArticleOrder});
        if(formattedContacts.length) paragraphs.push({type: 'Contacts', title: '', data: formattedContacts, order: formattedContacts[0].order});
        draft.data = {
          ...response[0],
          paragraphs: paragraphs.reduce((acc, val) => {
            if(val.type === 'File Article') {
              return [...acc, {...val, hasTitle: formattedEvents[0].order > fileArticleOrder }];
            }
            return [...acc, val];
          }, []).sort((a, b) => a.order - b.order),
          brand: response[0].brand[0]
        };
        break;
      }
      case TOGGLE_AUTOPLAY: {
        draft.autoPlay = !state.autoPlay;
        break;
      }
      case SCROLL_TO: {
        draft.scrollToRef = action.id;
        draft.scrollCount = state.scrollCount + 1;
        draft.autoPlay = action.isFromNews ? true : state.autoPlay;
        break;
      }

      default:
        return state;
    }
    return draft;
  });

export default landingPageReducer;
