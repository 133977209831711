const MainTitle = 'Skill 4 Business';

const ENV_BASE_PATH = {
  DEV: null,
  SIT: null,
  PRE_PROD: null,
  PROD: null,

  DEFAULT: '/'
};

const basePath =
  ENV_BASE_PATH[process.env.REACT_APP_DEPLOY_ENV] || ENV_BASE_PATH.DEFAULT;

const routes = {
  auth: {
    path: `${basePath}auth`,
    title: `Auth - ${MainTitle}`
  },
  home: {
    path: `${basePath}test-components`,
    title: `Home - ${MainTitle}`
  },
  notFound: {
    path: `${basePath}404`,
    title: 'Not Found'
  },
  landingPage: {
    path: `${basePath}`,
    title: `${MainTitle}`,
    showNavbar: false
  },
  news: {
    path: `${basePath}news`,
    title: `${MainTitle} - News`,
    showNavbar: false
  }
};

export default routes;
