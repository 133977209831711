import styled, { css } from 'styled-components';
import { colorOpacity } from 'utils/common';

export const CarouselSDSContainer = styled.div`
  width: 100%;
  min-height: 920px;
  height: fit-content;
  overflow: hidden;
  padding-top: 60px;
  @media ${props => props.theme.device.mobile} {
    min-height: fit-content;
  }
`;

export const BodyWrapper = styled.div`
  position: relative;
`;

export const BodyContainer = styled.div`
  width: 60%;
  margin-left: 240px;

  @media ${props => props.theme.device.tablet} {
    width: 80%;
    margin-left: 0;
  }

  @media ${props => props.theme.device.mobile} {
    width: 100%;
    margin-left: 0;
  }
`;
export const Title = styled.h2`
  margin-top: 10px;
`;
export const SubTitle = styled.h3`
  margin-top: 42px;
  @media ${props => props.theme.device.mobile} {
    margin-top: 20px;
  }
`;
export const Body = styled.div`
  margin-top: 20px;
  width: 60%;

  @media ${props => props.theme.device.tablet} {
    width: 80%;
  }

  @media ${props => props.theme.device.mobile} {
    width: 100%;
  }
`;

export const CarouselWrapper = styled.div`
  position: relative;
  left: -${props => props.margin}px;
  width: 100vw;
  ${props =>
    props.cardsLength < 5 &&
    css`
      & .slick-list {
        max-width: 1176px !important;
        margin: 0 auto !important;
      }
    `}

  & .slick-slide {
    width: 400px !important;
    & > div {
      padding: 0 30px !important;
    }
  }

  & .slick-track {
    margin: 0;
  }

  & .slick-list {
    margin: 0;
  }

  @media ${props => props.theme.device.mobile} {
    & .slick-slide {
      width: 205px !important;
      & > div {
        padding: 0 20px !important;
      }
    }
  }
`;

export const CardWrapper = styled.div`
  min-width: fit-content;
  -webkit-perspective: 600px;
  perspective: 600px;
  height: 450px;

  &.first-left {
    perspective-origin: -50% 50%;
    transform: scale(1.08);
  }
  &.second-left {
    perspective-origin: -100% 50%;
    transform: scale(1.31);
  }
  &.first-right {
    perspective-origin: 150% 50%;
    transform: scale(1.08);
  }
  &.second-right {
    perspective-origin: 200% 50%;
    transform: scale(1.31);
  }

  @media ${props => props.theme.device.mobile} {
    -webkit-perspective: 400px;
    perspective: 400px;
    height: 260px;
  }

  &.scaled-video {
    transition: 1s;
    transform: scale(1.4);
  }
`;

export const SDSCard = styled.div`
  width: 380px;
  height: 282px;
  margin: 100px auto 20px;
  box-shadow: 0 0 20px 10px rgba(50, 50, 50, 0.2);
  border-radius: 6px;
  overflow: hidden;
  transition: 1s;
  position: relative;
  display: flex;
  align-items: center;
  &.first-left {
    transform: rotateY(15deg);
  }
  &.second-left {
    transform: rotateY(15deg);
  }

  &.first-right {
    transform: rotateY(-15deg);
  }
  &.second-right {
    transform: rotateY(-15deg);
  }

  @media ${props => props.theme.device.mobile} {
    margin: 50px auto 20px;
    width: 205px;
    height: 145px;
    &.first-left {
      transform: rotateY(20deg);
    }
    &.first-right {
      transform: rotateY(-20deg);
    }
  }
  &:hover {
    img {
      transform: scale(1.2);
      cursor: none !important;
    }
  }
`;

export const Image = styled.img`
  transition: 0.4s ease-in-out;
  overflow: hidden;
  height: auto;
  width: 100%;
  max-width: initial;
`;

export const ColorBar = styled.div`
  width: 180px;
  height: 5px;
  margin: 0 auto;
  border-radius: 5px;
  background-color: ${props => props.theme.colors.primary.base};
  position: relative;
  bottom: 50px;
  @media ${props => props.theme.device.mobile} {
    width: 98px;
  }
`;

export const LinkWrapper = styled.div`
  margin-top: 10px;
  @media ${props => props.theme.device.mobile} {
    margin-top: 20px;
  }
`;
export const Link = styled.a`
  & :link,
  :visited {
    color: ${props => props.theme.colors.primary.base};
    text-decoration: underline;
  }
  & :hover,
  :active {
    color: ${props => props.theme.colors.primary.darker};
    text-decoration: none;
  }
`;

export const ArrowsContainer = styled.div`
  width: 210px;
  margin-right: 20%;
  margin-left: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 150px;
  right: 0;

  @media ${props => props.theme.device.tablet} {
    margin-right: 0;
  }

  @media ${props => props.theme.device.mobile} {
    width: 152px;
    margin: 40px auto 80px;
    position: static;
    margin: 40px auto 80px;
  }
`;

export const IconArrow = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  font-size: 47px;
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.primary.base};
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
  align-items: center;
  &:hover {
    box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.15);
  }
  &.left {
    transform: rotate(180deg);
  }
  @media ${props => props.theme.device.mobile} {
    width: 40px;
    height: 40px;
    font-size: 32px;
  }
`;

export const Counter = styled.div``;

export const Cursor = styled.div.attrs(props => ({
  style: {
    top: `${props.top}px`,
    left: `${props.left}px`
  }
}))`
  position: absolute;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  background-color: ${props =>
    colorOpacity(props.theme.colors.primary.base, 0.9)};
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  pointer-events: auto;
  cursor: none;

  & span {
    cursor: none;
  }
`;
export const IconPlay = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 40px;
  color: ${props => props.theme.colors.primary.base};
  border-radius: 50%;
  cursor: none !important;
  pointer-events: none;
`;
