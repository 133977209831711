import styled, { css } from 'styled-components';
import { Container } from '../../gridSystem';

export const SliderContainer = styled.div`
  width: 100%;
  height: ${props => (props.isLastSlider ? '512px' : '452px')};
  & div.slick-list {
    min-width: 100%;
  }
  @media ${props => props.theme.device.mobile} {
    height: ${props => (props.isLastSlider ? '490px' : '200px')};
    & div.slick-list {
      height: ${props => (props.isLastSlider ? '490px' : '200px')};
    }
  }
`;

export const CustomContainer = styled(Container)`
  ${({isLastSlider}) => !isLastSlider && css`
    position: relative;
    z-index: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    max-width: calc(100% - 2%) !important;
    margin: 0 !important;
  `}

  ${({ pullUp }) => pullUp && css`
    justify-content: start;
  `}

  ${({ mediaIsPhone }) => mediaIsPhone && css`
    margin: 1% !important;
    padding: 0 !important;
  `}
`;


export const Card = styled.div`
  position: relative;
  height: ${props => (props.isLastSlider ? '512px' : '452px')};
  background-image: url(${props => props.img});
  background-size: cover;
  background-position: center center;
  display: block !important;
  @media ${props => props.theme.device.mobile} {
    height: ${props => (props.isLastSlider ? '490px' : '200px')};
  }
  
  ${({isLastSlider}) => !isLastSlider && css`
    &::before {
      content: '';
      display: block;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.4);
    }
  `}
`;

export const Image = styled.img`
  height: 20%;
  margin: 1% 0 1% calc(-20px + 1%);
  position: relative;
  z-index: 1;
  align-self: start;

  ${({ pushDown }) => pushDown && css`
    margin-bottom: ${pushDown}%;
  `}

  ${({ mediaIsPhone }) => mediaIsPhone && css`
    margin-left: 0;
  `}
`;


export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 5% 0;

  @media ${props => props.theme.device.mobile} {
    padding-bottom: ${props => (props.hasCTA ? 0 : '35px')};
  }
`;

export const Title = styled.div`
  max-width: 100%;
  text-align: ${props => (props.isLastSlider ? 'left' : 'center')};
  margin-bottom: ${props => (props.isLastSlider ? '10px' : 0)};
  @media ${props => props.theme.device.mobile} {
    max-width: 100%;
  }
`;

export const Subtitle = styled.h3`
  max-width: 80%;
  text-align: center;
  margin-top: 15px;
  @media ${props => props.theme.device.mobile} {
    margin-top: 5px;
    max-width: 100%;
  }
`;

export const ButtonWrapper = styled.div`
  width: 300px;
  margin: ${props => (props.isLastSlider ? '0 auto 0 0' : '15px auto 0 auto')};

  @media ${props => props.theme.device.tablet} {
    width: 200px;
    margin: ${props => (props.isLastSlider ? '0 auto 0 0' : '5px auto 0 auto')};
  }

  @media ${props => props.theme.device.mobile} {
    width: 115px;
    margin: ${props => (props.isLastSlider ? '0 auto 0 0' : '5px auto 0 auto')};
  }
`;

export const TextBoxWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex !important;
  flex-direction: column-reverse;
`;

export const TextBoxImg = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(0%, -50%);  
  width: fit-content;
  max-width: 515px;
  padding: 40px 58px 40px 30px;
  background-color: ${props => props.theme.colors.white};

  @media ${props => props.theme.device.laptop} {
    transform: translate(-10%,-50%);  
  }

  @media ${props => props.theme.device.tablet} {
    transform: translate(0, 0);
    position: static;
    width: 305px;
    margin:0 auto 30px;
  }
  
  /* @media ${props => props.theme.device.tablet} {
    transform: translate(-50%,-50%);
    width: 305px;
  } */
`;

export const CtasWrapper = styled.div`
  width: 100%;
  margin-top: 30px;
  display: inline-flex;
  justify-content: flex-start;
  @media ${props => props.theme.device.tablet} {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin-top: 20px;
  }
`;