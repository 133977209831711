import { createAsyncActionType } from './index';

export const GET_LANDING_PAGE = createAsyncActionType(
  'landingPage',
  'GET_LANDING_PAGE'
);

export const TOGGLE_AUTOPLAY = '@@landingPage/TOGGLE_AUTOPLAY';

export const SCROLL_TO = '@@landingPage/SCROLL_TO';
