import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import 'react-multi-carousel/lib/styles.css';
import { Footer, RenderLandingParagraph, NavigationMenu } from 'ui/components';
import { withMediaQueries } from 'hoc/withMediaQueries';
import compose from 'utils/compose';
import { RowStripe, MainContent } from './style';

const LandingPage = ({ data, scrollToRef, scrollCount }) => {
  const [paragraphs, setParagraphs] = useState([]);

  const myRef = useRef(null);

  useEffect(() => {
    if (scrollToRef === '') {
      myRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [scrollToRef, scrollCount]);

  useEffect(() => {
    setParagraphs(data?.paragraphs);
  }, [data]);

  return (
    <>
      <NavigationMenu />
      <MainContent ref={myRef}>
        {paragraphs?.length &&
          paragraphs.map((item, index) => (
            <RowStripe key={`${item.type}-${index}`}>
              <RenderLandingParagraph
                item={item}
                itemIndex={index}
                onClick={url => {
                  window.open(url, '_blank');
                }}
              />
            </RowStripe>
          ))}
      </MainContent>
      <Footer />
    </>
  );
};

LandingPage.propTypes = {
  // HOC withMediaQueries

  // HOC (connect, state)
  data: PropTypes.object,
  scrollToRef: PropTypes.string,
  scrollCount: PropTypes.number

  // HOC (connect, dispatch)
};

const composed = compose(
  connect(
    state => {
      const {
        data,
        contentsCarousel: carousels,
        scrollToRef,
        scrollCount
      } = state.landingPage;
      return {
        data,
        carousels,
        scrollToRef,
        scrollCount
      };
    },
    () => ({})
  ),
  withMediaQueries
)(LandingPage);
export default composed;
