import styled, { css } from 'styled-components';

export const TrainingOfferContainer = styled.div`
  width: 100%;
  min-height: 452px;
  height: fit-content;
  padding-top: 100px;
  @media ${props => props.theme.device.tablet} {
    padding-top: 40px;
  }

  @media ${props => props.theme.device.mobile} {
    padding-top: 30px;
  }
`;

export const Title = styled.h2`
  max-width: 100%;
  margin-bottom: 60px;
  @media ${props => props.theme.device.tablet} {
    margin-bottom: 20px;
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media ${props => props.theme.device.mobile} {
    flex-direction: row;
    overflow-x: auto;
    margin: 0 -20px;
    &:after {
      content: '';
      margin-right: 20px;
    }
  }
`;

export const CardWrapper = styled.div`
  width: 400px;
  height: 400px;
  position: relative;
  overflow: hidden;

  @media ${props => props.theme.device.mobile} {
    min-width: 280px;
    height: 500px;
    margin-bottom: 20px;
    position: relative;
    display: flex;
    justify-content: center;
    margin-left: 20px;
    flex: 1 1 auto;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  width: 400px;
  height: 400px;
  background-image: url(${props => props.img});
  background-position: center center;
  background-size: cover;
  position: relative;
  padding: 20px 20px 40px 20px;

  @media ${props => props.theme.device.mobile} {
    width: 280px;
    height: 500px;
  }

  &:after {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      hsla(0, 0%, 100%, 0.2),
      hsla(0, 0%, 48%, 0.3),
      rgba(72, 72, 72, 0.34),
      rgba(3, 3, 3, 0.99)
    );
    z-index: 0;
  }
`;

export const IconPlus = styled.div`
  width: 15px;
  height: 15px;
  font-size: 20px;
  z-index: 1;
  margin-left: auto;
  color: ${props => props.theme.colors.white};
`;

export const IconClose = styled.div`
  width: 15px;
  height: 15px;
  font-size: 15px;
  margin-left: auto;
  color: ${props => props.theme.colors.white};
`;

export const IconLine = styled.div`
  display: block;
  width: 32px;
  height: 1px;
  background-color: #fff;
  margin-top: 16px;
`;

export const InfoContainer = styled.div`
  width: 400px;
  height: 400px;
  background-color: ${props => props.theme.colors.primary.base};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  padding: 15px;
  transform: translateY(calc(100% - 10px));

  @media ${props => props.theme.device.mobile} {
    width: 280px;
    height: 500px;
  }

  ${({ hasFocus }) =>
    hasFocus &&
    css`
      transform: translateY(0);
    `}

  transition: all .2s cubic-bezier(.645,.045,.355,1);
`;

export const BoxBody = styled.div`
  cursor: default;
  margin-top: 16px;
`;

export const ButtonWrapper = styled.div`
  width: 135px;
  height: 55px;
  margin-top: auto;
  @media ${props => props.theme.device.tablet} {
    width: 115px;
    height: 35px;
  }
`;

export const Label = styled.div`
  z-index: 1;
`;

export const Link = styled.div`
  font-family: 'Open sans';
  font-size: 12px;
  font-weight: 600;
  display: flex;
  color: ${props => props.theme.colors.white};
  flex-direction: row;
  align-items: center;
  z-index: 3;

  position: absolute;
  left: 15px;
  bottom: 15px;
  cursor: pointer;
`;

export const LinkText = styled.div`
  margin-right: 15px;
`;

export const LinkIcon = styled.div`
  font-weight: 600 !important;
  font-size: 16px;
`;
