import styled from 'styled-components';
import {
  FooterHeight,
  FooterHeightMobile,
  MenuHeight,
  MenuHeightLaptop,
  MenuHeightTablet,
  NavBarHeight,
  NavBarHeightMobile,
  NavBarHeightTablet
} from 'const';

const ContainerGray = styled.div.attrs(props => ({
  className: `${props.noMenu ? 'no-menu' : ''}`
}))`
  width: 100%;
  min-height: calc(100vh - ${NavBarHeight}px - ${FooterHeight}px - 70px);
  background-color: ${props => props.theme.colors.lighterGray};
  overflow-x: hidden;
  position: relative;
  padding-bottom: 20px;

  &.no-menu {
    min-height: calc(100vh - ${NavBarHeight}px - ${MenuHeight}px);

    @media ${props => props.theme.device.laptop} {
      min-height: calc(100vh - ${NavBarHeightTablet}px - ${MenuHeightLaptop}px);
    }
    @media ${props => props.theme.device.tablet} {
      min-height: calc(100vh - ${NavBarHeightTablet}px - ${MenuHeightTablet}px);
    }
  }

  @media ${props => props.theme.device.tablet} {
    min-height: calc(100vh - ${NavBarHeightTablet}px);
  }
  @media ${props => props.theme.device.mobile} {
    min-height: calc(
      100vh - ${NavBarHeightMobile}px - ${FooterHeightMobile}px - 70px
    );
  }
`;

export default ContainerGray;
