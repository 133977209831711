import { takeLatest, put, call, select } from 'redux-saga/effects';

import { LOADER_CLOSE, LOADER_OPEN, MODAL_OPEN } from 'redux/actions';
import { GET_LANDING_PAGE } from 'redux/actions/landingPage';

import landingPage from 'api/landingPage';
import ModalErrors from 'ui/components/Modals/ModalErrors';

function* landingPageGet() {
  try {
    yield put({ type: LOADER_OPEN });
    const { data: { title } = {} } = yield select(state => state.landingPage);
    if (!title) {
      const { data: { data: response = {} } = {} } = yield call(
        landingPage.getLandingPage
      );
      yield put({ type: GET_LANDING_PAGE._SUCCESS, response });
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: err?.message }
    });
    // eslint-disable-next-line no-console
    console.log('sagas > landingPage > landingPageGet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* landingPageGetWatch() {
  yield takeLatest(GET_LANDING_PAGE._REQUEST, landingPageGet);
}

export default [landingPageGetWatch()];
