import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withMediaQueries } from 'hoc/withMediaQueries';
import { connect } from 'react-redux';
import { menuRefs } from 'utils/menu';
import { Container } from 'ui/gridSystem';
import { SDSSettings } from 'utils/slider-settings';

import CustomCarousel from '../CustomCarousel';
import { HtmlWrapper } from '../RenderLandingParagraph/style';
import {
  CarouselSDSContainer,
  BodyContainer,
  SubTitle,
  Title,
  Body,
  CarouselWrapper,
  SDSCard,
  ColorBar,
  CardWrapper,
  ArrowsContainer,
  IconArrow,
  Counter,
  /*LinkWrapper,
  Link,*/
  BodyWrapper,
  Image,
  Cursor, IconPlay
} from './style';
import OpenSans from '../../typography/openSans';
import VideoPlayer from '../VideoPlayer';

function CarouselSDS({ mediaIsPhone, item, cards, scrollToRef, scrollCount }) {
  const [size, setSize] = useState();
  const [slideType, setSlideType] = useState();
  const [clickCounter, setClickCounter] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentText, setCurrentText] = useState(0);
  const [cursor, setCursor] = useState({});
  const [showCursor, setShowCursor] = useState(false);
  const [goToSlideIndex, setGoToSlideIndex] = useState();
  const [videoSrc, setVideoSrc] = useState('');

  const myRef = useRef(null);

  useEffect(() => {
    if (scrollToRef === menuRefs.CAROUSEL_SDS) {
      myRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [scrollToRef, scrollCount]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize(
        document.getElementsByClassName('container')[0]?.clientWidth - 40
      );
    }
    function animation(e) {
      setCursor({ top: e.clientY, left: e.clientX });
    }
    window.addEventListener('resize', updateSize);
    window.addEventListener('mousemove', animation);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document]);

  const onSlideChange = (current, next) => {
    setCurrentSlide(next);
    setCurrentText(next);
  };

  const getClass = idx => {
    switch (idx - currentSlide) {
      case 1 - cards.length:
      case 1:
        return 'first-right';
      case cards.length - 1:
      case -1:
        return 'first-left';
      case 2 - cards.length:
      case 2:
        return 'second-right';
      case cards.length - 2:
      case -2:
        return 'second-left';

      default:
        break;
    }
  };

  const handleSlide = type => {
    setClickCounter(clickCounter + 1);
    setSlideType(type);
    setVideoSrc('');
  };

  const mouseOver = idx => {
    setCurrentText(idx);
    setShowCursor(true);
  };

  const mouseOut = () => {
    setShowCursor(false);
  };

  const evaluateTop = idx => {
    const offsetCentral = idx === currentSlide ? 0 : 0;

    return (
      cursor.top
      - 100
      - document.getElementById(`card-${idx}`).getBoundingClientRect().y
      - offsetCentral
    );
  };

  const evaluateLeft = idx => {
    const offsetClone =      idx - currentSlide === cards.length - 1
        ? 0
        : idx - currentSlide === 1 - cards.length
        ? cards.length * 800
        : cards.length * 400;
    const offsetCentral = idx === currentSlide ? 0 : 20;

    return (
      cursor.left
      - document.getElementById(`card-${idx}`).getBoundingClientRect().x
      - offsetClone
      - offsetCentral
    );
  };

  const onClickSDSCard = (item, index) => {
    const { cta_1_url, video_id } = item;
    setGoToSlideIndex(index);
    if(video_id) {
      setVideoSrc(video_id);
    } else {
      window.open(cta_1_url, '_blank');
    }
  };

  const onAfterChangeSlider = (index) => {
    setGoToSlideIndex(index);
    if(cards[index] && !cards[index].video_id) setVideoSrc('');
  };

  return (
    <CarouselSDSContainer ref={myRef}>
      <Container>
        <CarouselWrapper
          margin={(window.innerWidth - size) / 2}
          cardsLength={cards?.length}
        >
          <CustomCarousel
            config={{
              ...SDSSettings,
              centerPadding: mediaIsPhone
                ? `${(document.body.offsetWidth - 205) / 2}px`
                : '100px'
            }}
            isSDS
            onSlideChange={onSlideChange}
            slideType={slideType}
            clickCounter={clickCounter}
            onAfterChange={onAfterChangeSlider}
            goToSlideIndex={goToSlideIndex}
          >
            {cards.map((c, idx) => (
              <CardWrapper
                key={`card-${idx}`}
                className={`${getClass(idx)} ${c.video_id && idx === goToSlideIndex && videoSrc ? 'scaled-video' : ''}`}
                id={`card-${idx}`}
              >
                <SDSCard
                  className={getClass(idx)}
                  onMouseOver={() => mouseOver(idx)}
                  onFocus={() => mouseOver(idx)}
                  onMouseOut={mouseOut}
                  onBlur={mouseOut}
                  onClick={() => onClickSDSCard(c, idx)}
                >
                  {(!c.video_id || ((c.video_id && !videoSrc) || idx !== goToSlideIndex)) && (
                  <React.Fragment>
                    <Image src={c.image} />
                    {c.video_id && <IconPlay className="icon-icons-play" />}
                    {showCursor && currentText === idx && (
                    <Cursor
                      className="cursor"
                      top={evaluateTop(idx)}
                      left={evaluateLeft(idx)}
                    >
                      <OpenSans type="bold">{c.video_id ? 'riproduci' : 'leggi'}</OpenSans>
                    </Cursor>
                    )}
                  </React.Fragment>
)}
                  {c.video_id && idx === goToSlideIndex && videoSrc && idx === goToSlideIndex && <VideoPlayer image={c.image} videoId={videoSrc} />}
                </SDSCard>
              </CardWrapper>
            ))}
          </CustomCarousel>
          {!videoSrc && <ColorBar />}
        </CarouselWrapper>
        <BodyWrapper>
          <BodyContainer>
            <Title>
              <OpenSans
                type="paragraphTitle"
                configuration={mediaIsPhone ? { fontSize: 20 } : null}
              >
                {item?.title}
              </OpenSans>
            </Title>
            <SubTitle>
              <OpenSans
                type="sdsTitle"
                configuration={mediaIsPhone ? { fontSize: 16 } : null}
              >
                {cards[currentText].subtitle}
              </OpenSans>
            </SubTitle>
            <Body>
              <OpenSans
                type="digitalTraining"
                configuration={mediaIsPhone ? { fontSize: 14 } : null}
              >
                <HtmlWrapper
                  dangerouslySetInnerHTML={{ __html: cards[currentText].body }}
                />
              </OpenSans>
            </Body>
            {/* {cards[currentText] && (
              <LinkWrapper>
                <Link href={cards[currentText].cta_1_url} target="_blank">
                  <ProDisplay
                    type="link"
                    configuration={{
                      textDecoration: 'underline',
                      fontSize: mediaIsPhone ? 14 : 16
                    }}
                  >
                    Continua a leggere
                  </ProDisplay>
                </Link>
              </LinkWrapper>
            )} */}
          </BodyContainer>
          <ArrowsContainer>
            <IconArrow
              className="icon-btn-arrow left "
              onClick={() => handleSlide('prev')}
            />
            <Counter>
              <OpenSans
                type="totalCost"
                configuration={{
                  fontSize: mediaIsPhone ? 16 : 25,
                  fontWeight: 700
                }}
              >
                {currentText + 1}
              </OpenSans>
              <OpenSans
                type="digitalTrainingTitle"
                configuration={{
                  fontSize: mediaIsPhone ? 14 : 18,
                  fontWeight: 'normal'
                }}
              >
                /{cards.length}
              </OpenSans>
            </Counter>
            <IconArrow
              className="icon-btn-arrow "
              onClick={() => handleSlide('next')}
            />
          </ArrowsContainer>
        </BodyWrapper>
      </Container>
    </CarouselSDSContainer>
  );
}

CarouselSDS.propTypes = {
  mediaIsPhone: PropTypes.bool,
  item: PropTypes.object,
  cards: PropTypes.array,
  scrollToRef: PropTypes.string,
  scrollCount: PropTypes.number
};

export default connect(state => {
  const { scrollToRef, scrollCount } = state.landingPage;
  return { scrollToRef, scrollCount };
})(withMediaQueries(CarouselSDS));
