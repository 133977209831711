import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getMenuLabel } from 'utils/menu';
import routes from 'routes';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import { SCROLL_TO } from 'redux/actions/landingPage';
import {
  Award,
  BurgerButton,
  BurgerMenuContainer,
  Logo,
  LogoContainer,
  MenuContainer,
  FixedContainer,
  SideMenu,
  Mask,
  IconClose,
  MenuItem,
  MenuList
} from './style';
import logo1x from '../../../assets/img/Skills4ESG_white.png';
import logo2x from '../../../assets/img/Skills4ESG_white.png';
import logo3x from '../../../assets/img/Skills4ESG_white.png';
import OpenSans from '../../../typography/openSans';
import award from '../../../assets/img/Logo_premioMF.png'

const MobileNavigationMenu = ({ menu, pushUrl, scrollToRef }) => {
  const [listMenu, setListMenu] = useState(menu);
  const [menuOpen, setMenuOpen] = useState(false);
  useEffect(() => {
    setListMenu(menu);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menu]);

  const onClickOpenMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const htmlElement = document.querySelector('body');
    menuOpen
      ? htmlElement.classList.add('disabled-scroll')
      : htmlElement.classList.remove('disabled-scroll');
  }, [menuOpen]);

  return (
    <>
      <FixedContainer>
        <MenuContainer>
          <LogoContainer>
            <Logo
              src={logo1x}
              srcSet={`${logo1x} 300w, ${logo2x} 768w, ${logo3x} 1280w`}
              onClick={() => {
                const isFromNews = window.location.href.includes('news');
                pushUrl(routes.landingPage.path);
                scrollToRef('', isFromNews);
              }}
            />
            <Award src={award} />
          </LogoContainer>
          <BurgerMenuContainer>
            <BurgerButton
              className="icon-icons-menu"
              onClick={onClickOpenMenu}
            />
          </BurgerMenuContainer>
        </MenuContainer>
      </FixedContainer>
      <SideMenu isOpen={menuOpen}>
        <Logo
          src={logo1x}
          srcSet={`${logo1x} 300w, ${logo2x} 768w, ${logo3x} 1280w`}
          sideMenu
        />
        <IconClose className="icon-close-x" onClick={onClickOpenMenu} />
        <MenuList>
          {listMenu.map((item, index) => {
            if (getMenuLabel(item.value))
              return (
                <MenuItem
                  key={`${item.value}-${index}`}
                  onClick={() => {
                    onClickOpenMenu();
                    item.onClick();
                  }}
                >
                  <OpenSans
                    type="navigationMenuLink"
                    configuration={{ fontSize: 16, fontWeight: 300, color: 'white' }}
                  >
                    {getMenuLabel(item.value)}
                  </OpenSans>
                </MenuItem>
              );
            return null;
          })}
        </MenuList>
      </SideMenu>
      <Mask isOpen={menuOpen} onClick={onClickOpenMenu} />
    </>
  );
};

MobileNavigationMenu.propTypes = {
  menu: PropTypes.array.isRequired,
  pushUrl: PropTypes.func,
  scrollToRef: PropTypes.func
};

export default connect(
  () => ({}),
  dispatch => ({
    pushUrl: url => dispatch(push(url)),
    scrollToRef: (id, isFromNews) =>
      dispatch({ type: SCROLL_TO, id, isFromNews })
  })
)(MobileNavigationMenu);
