import { NavBarHeight, NavBarHeightMobile } from 'const';
import styled from 'styled-components';

export const HeaderWrapper = styled.header`
  padding-top: ${NavBarHeight}px;
  min-height: 70px;
  background-color: ${props => props.theme.colors.primary.darker};

  @media ${props => props.theme.device.mobile} {
    min-height: ${props => (props.showSurvey ? '140px' : '70px')};
    padding-top: ${NavBarHeightMobile}px;
  }
`;

export const Header = styled.header`
  min-height: 70px;
  display: flex;
  align-items: center;

  @media ${props => props.theme.device.mobile} {
    min-height: ${props => (props.showSurvey ? '140px' : '70px')};
    flex-direction: column;
  }
`;

export const FirstHalfWrapper = styled.div`
  min-height: 70px;

  @media ${props => props.theme.device.mobile} {
    width: 100vw;
    border-bottom: 1px solid white;
  }
`;

export const FirstHalf = styled.div`
  display: flex;
  width: 100%;
  min-height: 70px;
`;

export const HeaderIcon = styled.div`
  width: 70px;
  color: ${props => props.theme.colors.white};
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;

  &.first {
    transform: rotate(180deg);
  }
`;

export const Title = styled.div`
  min-height: 70px;
  border-left: 1px solid ${props => props.theme.colors.darkGray};
  padding: 10px 32px;

  @media ${props => props.theme.device.mobile} {
    min-width: calc(100% - 70px);
    padding: 10px 12px;
  }
`;

export const Text = styled.div``;

export const Main = styled.main`
  margin: 30px 0;
`;

export const Cover = styled.div`
  background-image: url(${props => props.cover});
  background-size: cover;
  background-position: center;
  height: 400px;
  width: 100%;
  @media ${props => props.theme.device.mobile} {
    height: 250px;
  }
`;

export const NewsTitle = styled.div`
  margin: 20px 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Duration = styled.div`
  display: flex;
  align-items: center;
  margin: 14px 0 20px;
`;

export const IconClock = styled.span`
  color: ${props => props.theme.colors.lightGray};
  font-size: 18px;
  width: 18px;
  height: 18px;
  margin-right: 7px;
`;

export const Body = styled.div`
  margin-bottom: 100px;
`;
