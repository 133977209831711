import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { withMediaQueries } from '../../../hoc/withMediaQueries';
import DesktopFileArticleCard from './desktop';
import MobileFileArticleCard from './mobile';

const FileArticleCard = ({ data, mediaIsPhone }) => {
  const [info, setInfo] = useState(data);
  useEffect(() => {
    setInfo(data);
  }, [data]);
  if (mediaIsPhone) {
    return <MobileFileArticleCard data={info} />;
  }
  return <DesktopFileArticleCard data={info} />;
};

FileArticleCard.propTypes = {
  // HOC (withMediaQueries)
  mediaIsPhone: PropTypes.bool.isRequired
};

export default withMediaQueries(FileArticleCard);
