import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import routes from 'routes';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import ContainerGray from 'pages/style';
import { Container } from 'ui/gridSystem';
import { Footer, HtmlRaw, NavigationMenu } from 'ui/components';
import { SCROLL_TO } from 'redux/actions/landingPage';
import { menuRefs } from 'utils/menu';
import {
  HeaderWrapper,
  Header,
  HeaderIcon,
  Main,
  Text,
  Title,
  FirstHalf,
  FirstHalfWrapper,
  IconClock,
  Cover,
  NewsTitle,
  Duration,
  Body
} from './style';
import OpenSans from '../../ui/typography/openSans';

function News({ paragraphs, pushUrl, scrollToRef }) {
  const [news, setNews] = useState({});
  const { id } = useParams();

  const myRef = useRef(null);

  useEffect(() => {
    if (id && paragraphs) {
      const {
        news: { data: allNews }
      } = paragraphs.find(p => p.type === 'News ed Eventi');
      if (allNews) setNews(allNews.find(n => n.id?.toString() === id));
    }
    myRef.current.scrollIntoView();
  }, [id, paragraphs]);

  return (
    <>
      <NavigationMenu />
      <HeaderWrapper ref={myRef}>
        <Container>
          <Header>
            <FirstHalfWrapper>
              <Container>
                <FirstHalf>
                  <HeaderIcon
                    className="icon-arrow-right first"
                    onClick={() => {
                      pushUrl(routes.landingPage.path);
                      setTimeout(() => scrollToRef(menuRefs.NEWS_EVENTS), 10);
                    }}
                  />
                  <Title>
                    <Text>
                      <OpenSans type="playlistOvertitle"
                                configuration={{ color: 'white' }}>
                        News
                      </OpenSans>
                    </Text>
                    <Text className="flex-start">
                      <OpenSans
                        type="playlistTitle"
                        configuration={{ color: 'white' }}
                      >
                        {news?.title}
                      </OpenSans>
                    </Text>
                  </Title>
                </FirstHalf>
              </Container>
            </FirstHalfWrapper>
          </Header>
        </Container>
      </HeaderWrapper>
      <ContainerGray>
        <Container>
          <Main>
            <Cover cover={news?.cover} />
            <NewsTitle>
              <OpenSans type="newsTitle">{news?.title}</OpenSans>
            </NewsTitle>
            <Duration>
              <IconClock className="icon-clock" />
              <Text>
                <OpenSans type="clockLabel">
                  {Math.round(news?.duration / 60)} min
                </OpenSans>
              </Text>
            </Duration>
            <Body>
              <OpenSans type="newsBody" configuration={{ color: 'mineShaft' }}>
                <HtmlRaw html={news?.body} />
              </OpenSans>
            </Body>
          </Main>
        </Container>
      </ContainerGray>
      <Footer />
    </>
  );
}

News.propTypes = {
  paragraphs: PropTypes.array,
  pushUrl: PropTypes.func,
  scrollToRef: PropTypes.func
};

export default connect(
  state => {
    const {
      data: { paragraphs }
    } = state.landingPage;
    return { paragraphs };
  },
  dispatch => ({
    pushUrl: url => dispatch(push(url)),
    scrollToRef: id => dispatch({ type: SCROLL_TO, id, isFromNews: true })
  })
)(News);
