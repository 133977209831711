import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Container } from 'ui/gridSystem';
import Modal, { stopEvent } from 'ui/components/Modals/Modal';

import { ModalGlobalContainer } from 'ui/components/Modals/Modal.style';

import Img from 'ui/assets/img/error.png';
import { MODAL_CLOSE } from 'redux/actions';
import { Title, Subtitle, ContentWrapper, Icon, Image } from './style';

import { ModalWrapper2 } from '../Modal.style';
import OpenSans from '../../../typography/openSans';

const modalId = 'ui/components/ModalErrors';

const ModalErrors = ({ errorTitle, errorText, onClose }) => (
  <Modal id={modalId}>
    <Container>
      <ModalWrapper2>
        <ModalGlobalContainer onMouseDown={stopEvent} onClick={stopEvent}>
          <ContentWrapper>
            <Icon className="icon-close-x" onClick={onClose} />
            <Image src={Img} />
            <Title>
              <OpenSans type="modalTitle">
                {errorTitle || 'Errore'}
              </OpenSans>
            </Title>
            <Subtitle>
              <OpenSans
                configuration={{
                  lineHeight: 1.5,
                  letterSpacing: '0.25px',
                  fontSize: '18'
                }}
              >
                {errorText || 'Siamo spiacenti, si è verificato un errore'}
              </OpenSans>
            </Subtitle>
          </ContentWrapper>
        </ModalGlobalContainer>
      </ModalWrapper2>
    </Container>
  </Modal>
);

ModalErrors.propTypes = {
  // HOC (connect, state)
  errorText: PropTypes.string,
  errorTitle: PropTypes.string,

  // HOC (connect, dispatch)
  onClose: PropTypes.func.isRequired
};

ModalErrors.id = modalId;

export default connect(
  state => ({
    errorText: state.modal.payload?.errorText,
    errorTitle: state.modal.payload?.errorTitle
  }),
  dispatch => ({
    onClose: () => dispatch({ type: MODAL_CLOSE })
  })
)(ModalErrors);
