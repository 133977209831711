export const breakpoint = {
  mobile: 320,
  tablet: 768,
  laptop: 1024,
  desktop: 1200
};

const size = {
  mobile: `${breakpoint.mobile}px`,
  tablet: `${breakpoint.tablet}px`,
  laptop: `${breakpoint.laptop}px`,
  desktop: `${breakpoint.desktop}px`
};

const device = {
  mobile: `(max-width: ${breakpoint.tablet - 1}px)`,
  tablet: `(max-width: ${breakpoint.laptop - 1}px)`,
  laptop: `(max-width: ${breakpoint.desktop - 1}px)`,
  desktop: `(min-width: ${size.desktop})`
};

export default device;
