import styled from 'styled-components';
import { colorOpacity } from 'utils/common';
import { checkRounded } from 'ui/scss/app.scss';

export const Background = styled.div`
  background-color: ${props => props.theme.colors.mineShaft};
  z-index: -10;
`;

export const LandingPageContainer = styled.div`
  min-height: 610px;
  background-color: ${props =>
    props?.bgColor ? props.bgColor : props.theme.colors.transparent};
  background-position: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: ${props => (props.mediaIsLeft ? 'row-reverse' : 'row')};
  height: 100%;

  @media ${props => props.theme.device.mobile} {
    flex-wrap: wrap;
    min-height: 440px;
  }
  &.body-only {
    min-height: fit-content;
  }
`;

export const TitleWrapper = styled.div`
  width: 100%;
`;

export const Title = styled.div`
  padding-bottom: 6px;
  word-break: break-word;
  color: ${props => props.theme.colors.primary.base};

  @media ${props => props.theme.device.mobile} {
    padding-bottom: 14px;
    &.lateral-padding {
      padding-right: 10px;
      padding-left: 10px;
    }
  }
`;

export const OverTitle = styled.div`
  padding-bottom: 60px;

  color: ${props => props.theme.colors.mineShaft};

  @media ${props => props.theme.device.tablet} {
    padding-bottom: 12px;
  }
  @media ${props => props.theme.device.mobile} {
    padding-bottom: 5px;
  }
`;

export const SubTitle = styled.div`
  padding-bottom: 16px;

  color: ${props => props.theme.colors.primary.base};

  @media ${props => props.theme.device.tablet} {
    padding-bottom: 12px;
  }
  @media ${props => props.theme.device.mobile} {
    padding-bottom: 5px;
  }
`;

export const Text = styled.div`
  padding-bottom: 48px;
  padding-right: 60px;
  color: ${props => props.theme.colors.greenDark};

  @media ${props => props.theme.device.tablet} {
    padding-bottom: 27px;
  }
  @media ${props => props.theme.device.mobile} {
    padding-right: 0;
    padding-bottom: 23px;
  }
`;

export const Buttons = styled.div`
  display: inline-flex;
  @media ${props => props.theme.device.mobile} {
    display: flex;
    flex-direction: column-reverse;
  }
`;

export const ButtonWrapperStore = styled.div`
  min-width: 170px;
  margin-right: 25px;

  @media ${props => props.theme.device.laptop} {
    min-width: 220px;
    margin-right: 24px;
  }
  @media ${props => props.theme.device.tablet} {
    min-width: 229px;
    margin-right: 15px;
  }
  @media ${props => props.theme.device.mobile} {
    min-width: 100%;
  }
`;

export const ButtonWrapper = styled.div`
  min-width: 180px;
  margin-right: 20px;
  @media ${props => props.theme.device.tablet} {
    width: 233px;
    margin-bottom: 15px;
    margin-right: 0;
  }
  @media ${props => props.theme.device.mobile} {
    width: 100%;
  }
`;

export const VideoContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: ${props => (props.mediaIsLeft ? 'row-reverse' : 'row')};
  margin-top: 150px;

  @media ${props => props.theme.device.mobile} {
    flex-direction: column;
    position: relative;
    margin-top: 0;
  }
`;

export const Video = styled.div`
  width: 400px;
  height: 100px;
  background-color: yellow;
`;

export const TextBox = styled.div`
  max-width: 50%;
  padding: 50px;
  background-color: ${props =>
    props?.bgColor ? props.bgColor : props.theme.colors.transparent};

  @media ${props => props.theme.device.mobile} {
    width: 100%;
    max-width: unset;
  }
`;

export const VideoBox = styled.div`
  max-width: 50%;
  width: 50%;
  height: 340px;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center center;
  display: flex;
  justify-content: center;
  align-items: center;

  & button.vjs-big-play-button {
    width: 80px;
    height: 80px;
    padding: 10px;
    border-radius: 24px;
    -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px);
    color: ${props => props.theme.colors.white};
    font-size: 55px;
    background-color: rgba(255, 255, 255, 0.4);
    cursor: pointer;
    border: none;
  }
  & .vjs-poster {
    background-size: cover;
  }

  @media ${props => props.theme.device.mobile} {
    top: 312px;
    width: 316px;
    height: 166px;
    max-width: unset;
    margin: 40px 0;
  }
`;

export const Body = styled.div`
  width: 100%;
  padding: 25px 0;
  display: flex;
  align-items: center;
  @media ${props => props.theme.device.mobile} {
    &.lateral-padding {
      padding-right: 10px;
      padding-left: 10px;
    }
  }
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: ${props => (props.movedLeft ? 'flex-start' : 'flex-end')};

  @media ${props => props.theme.device.mobile} {
    align-items: center;
    padding: 30px 30px 0;
    order: 2;
  }
`;

export const BodyWrapper = styled.div`
  width: 50%;
  z-index: 1;
  @media ${props => props.theme.device.mobile} {
    width: initial;
  }
`;

export const ImageContainer = styled.div`
  height: 100%;
  position: absolute;
  top: 0;
  left: ${props => (props.stayLeft ? 0 : 'auto')};
  right: ${props => (!props.stayLeft ? 0 : 'auto')};
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: ${props => (props.stayLeft ? 'flex-end' : 'flex-start')};
  @media ${props => props.theme.device.laptop} {
    width: 53%;
  }
  @media ${props => props.theme.device.tablet} {
    width: 53%;
  }
  @media ${props => props.theme.device.mobile} {
    position: relative;
    width: 100%;
    justify-content: center;
  }
`;

export const ImageWrapper = styled.div`
  max-height: 640px;

  &.aligned-start {
    align-self: flex-start;
    margin-right: 0;
    justify-content: flex-end;
  }
`;

export const Image = styled.img`
  position: relative;
  top: 0;
  right: 0;
  max-width: 100%;
  height: auto;
  max-height: 640px;
`;

export const ContainerWrapper = styled.div`
  background-color: white;
  margin: 0 auto;
  position: relative;
  overflow-x: hidden;
`;

export const BodyContainerRight = styled(BodyContainer)`
  justify-content: flex-end;
`;

export const PreTitle = styled.div`
  padding-bottom: 16px;

  @media ${props => props.theme.device.tablet} {
    padding-bottom: 12px;
  }
  @media ${props => props.theme.device.mobile} {
    padding-bottom: 5px;
  }
`;

export const TitleWrapperRight = styled.div`
  width: 40%;
  @media ${props => props.theme.device.laptop} {
    width: 60%;
  }
  @media ${props => props.theme.device.tablet} {
    width: 64%;
  }
  @media ${props => props.theme.device.mobile} {
    width: 100%;
  }
`;

export const HtmlWrapper = styled.div`
  width: 100%;
  min-height: min-content;

  & ul {
    margin-top: 23px;
  }

  & li {
    position: relative;
    padding-left: 30px;
    margin-bottom: 5px;
  }
  & li::after {
    content: ${checkRounded};
    font-family: 's4b' !important ;
    font-size: 20px;
    height: 20px;
    color: ${props => props.color ? props.color : props.theme.colors.primary.base};
    position: absolute;
    top: -1px;
    left: 0;
  }
`;

export const CtasWrapper = styled.div`
  width: 100%;
  margin-top: 30px;
  display: inline-flex;
  justify-content: flex-start;
  @media ${props => props.theme.device.tablet} {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
`;

export const Placeholder = styled.div`
  width: 100%;
  height: 640px;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center center;
`;

export const Layout = styled.div`
  background-image: linear-gradient(
    -45deg,
    rgba(187, 58, 54, 0.8),
    rgba(234, 129, 102, 0.4)
  );
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const VideoTitle = styled.div`
  color: ${props => props.theme.colors.white};
  text-align: center;
  width: 500px;

  @media ${props => props.theme.device.tablet} {
    width: 300px;
  }
`;

export const VideoSubtitle = styled(VideoTitle)`
  margin-bottom: 54px;
`;

export const IconPlay = styled.div`
  font-size: 60px;
  color: ${props => props.theme.colors.white};
  width: 100px;
  height: 100px;
  padding: 20px 25px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.3);
  cursor: pointer;
`;

export const CarouselWrapper = styled.div`
  width: 100%;
  margin-top: 30px;

  & > .react-multi-carousel-list {
    position: static;
  }
`;

export const LandingCard = styled.div`
  width: 300px !important;
  height: 407px;
  background-image: url(${props => props.image});
  background-size: cover;
  transition-delay: 250ms;
  margin-right: 20px;
  @media (max-width: 370px) {
    width: 280px !important;
    height: 387px;
  }
`;

export const Overlay = styled.div`
  width: 100%;
  min-height: 100%;
  background-color: ${props => colorOpacity(props.theme.colors.jet, 0.6)};
  padding: 28px;
`;

export const CardTitle = styled.div`
  margin-top: 85%;
  margin-bottom: 10px;

  &.top {
    margin-top: 0;
  }
`;

export const CardBody = styled.div``;

export const CoursesContainer = styled.div`
  overflow: visible;
`;

export const CourseWrapper = styled.div`
  padding: 7px 0;
  border-bottom: 1px solid ${props => props.theme.colors.white};
`;

export const Duration = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
`;

export const IconPlaySmall = styled.div`
  width: 14px;
  height: 14px;
  line-height: 14px;
  border-radius: 14px;
  font-size: 10px;
  text-align: center;
  margin-right: 4px;
  background-color: ${props => props.theme.colors.white};
  opacity: 0.8;
`;

export const DotsWrapper = styled.div`
  margin-top: 15px;
  display: flex;
`;

export const Dot = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 5px;
  margin-right: 3px;
  opacity: 0.8;
  background-color: ${props => props.theme.colors.white};
`;

export const BackgroungImgWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const BackgroungImg = styled.div`
  width: 100%;
  height: fit-content;
  padding: 44px 100px;
  background-image: url(${props => props.image});
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: ${props => (props.tbIsLeft ? 'flex-start' : 'flex-end')};
  align-items: center;
  background-position: right;

  @media ${props => props.theme.device.mobile} {
    background-position: ${props => (props.tbIsLeft ? 'right' : 'left')};
    padding: 10px;
    min-height: 350px;
  }
`;

export const TextBoxImg = styled.div`
  max-width: 380px;
  /* height: fit-content; */
  padding: 40px 58px 40px 30px;
  background-color: ${props => props.theme.colors.white};
  height: 350px;

  @media ${props => props.theme.device.mobile} {
    max-width: 100%;
    height: initial;
    min-height: 350px;
  }
`;

export const BodiesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 15px;

  & > div {
    max-width: ${props => (props.isDouble ? '45%' : '100%')};
  }

  @media ${props => props.theme.device.mobile} {
    flex-direction: column;
    & > div {
      max-width: 100%;
    }
  }
`;
