import { getLandingPageMock, shouldUseMock } from './mock';
import { apiCall, baseUrl, extendUrl } from './config';

export default {
  getLandingPage: () => {
    if (shouldUseMock()) {
      return getLandingPageMock();
    }
    return apiCall(`${baseUrl}${extendUrl}landing_page`, 'GET', null, {
      isDrupal: true
    });
  }
};
