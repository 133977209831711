import styled, { css } from 'styled-components';

export const HRC = styled.div`
  ${props =>
    props.withEllipsis &&
    css`
      & p:after {
        content: ' [...]'};
      }
    `}
`;
