import landingPage from './landingPage';
import modal from './modal';
import app from './app';
import domain from './domain';

export default {
  app,
  landingPage,
  modal,
  domain
};
