import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withMediaQueries } from 'hoc/withMediaQueries';
import { Container } from 'ui/gridSystem';
import { Button, Tooltip } from 'ui/atoms';
import { TOGGLE_AUTOPLAY } from 'redux/actions/landingPage';
import { CollectionsSettings } from 'utils/slider-settings';
import CustomCarousel from '../CustomCarousel';

import { HtmlWrapper } from '../RenderLandingParagraph/style';
import HtmlRaw from '../HtmlRaw';
import {
  DigitalTrainingContainer,
  ContainerMain,
  BodyContainer,
  Logo,
  Title,
  Body,
  Label,
  LabelImage,
  ButtonWrapper,
  PlayBtnWrapper,
  IconPlay,
  CarouselWrapper,
  CollectionCard,
  Overlay,
  CardTitle,
  CardBody,
  CardContent,
  CourseWrapper,
  CoursesContainer,
  Duration,
  IconPlaySmall,
  BackgroundImg
} from './style';
import OpenSans from '../../typography/openSans';

function DigitalTraining({
  mediaIsPhone,
  item,
  onClick,
  handleAutoPlay,
  autoPlay,
  collections
}) {
  const [size, setSize] = useState();
  const [showOver, setShowOver] = useState();
  const [timeoutId, setTimeoutId] = useState();

  useLayoutEffect(() => {
    function updateSize() {
      setSize(
        document.getElementsByClassName('container')[0]?.offsetWidth - 40
      );
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document]);

  const handleFocus = (id = undefined) => {
    if (id === undefined) clearTimeout(timeoutId);
    const tId = setTimeout(() => {
      setShowOver(id);
    }, 150);
    setTimeoutId(tId);
  };

  return (
    <DigitalTrainingContainer>
      <Container>
        <ContainerMain>
          <BackgroundImg img={item.image} size={size} />
          <Label>
            <LabelImage img={item.label_image} />
            <OpenSans
              type="progressCardTitle"
              configuration={mediaIsPhone ? { fontSize: 16 } : null}
            >
              {item.label_title}
            </OpenSans>
          </Label>
          <BodyContainer>
            <Logo img={item.logo} />
            <Title>
              <OpenSans
                type="digitalTrainingTitle"
                configuration={mediaIsPhone ? { fontSize: 20 } : null}
              >
                {item.title}
              </OpenSans>
            </Title>
            <Body>
              <OpenSans
                type="digitalTraining"
                configuration={mediaIsPhone ? { fontSize: 14 } : null}
              >
                <HtmlWrapper dangerouslySetInnerHTML={{ __html: item.body }} />
              </OpenSans>
            </Body>
            {item.cta_1_url && (
              <ButtonWrapper>
                <Button
                  text={item.cta_1_title?.toUpperCase()}
                  type={Button.TYPE.PRIMARY}
                  onClick={() => onClick(item.cta_1_url)}
                  mediaIsPhone={mediaIsPhone}
                />
              </ButtonWrapper>
            )}
            <PlayBtnWrapper onClick={() => handleAutoPlay()}>
              <IconPlay
                className={autoPlay ? 'icon-icons-pause' : 'icon-icons-play'}
              />
              <OpenSans
                type="infoBoxTitle"
                configuration={
                  mediaIsPhone
                    ? { fontSize: 12 }
                    : { fontSize: 18 }
                }
              >
                {autoPlay ? 'PAUSA' : 'AVVIA'}
              </OpenSans>
            </PlayBtnWrapper>
          </BodyContainer>
          <CarouselWrapper
            margin={(window.innerWidth - size) / 2}
            windowWidth={window.innerWidth}
          >
            <CustomCarousel
              config={{
                ...CollectionsSettings,
                speed: autoPlay ? (mediaIsPhone ? 6000 : 5000) : 250
              }}
              isAutoPlay
            >
              {collections.map((c, idx) => (
                <CollectionCard
                  key={`card-${idx}`}
                  img={c.cover}
                  onMouseEnter={() => handleFocus(`card-${idx}`)}
                  onMouseLeave={() => handleFocus()}
                >
                  <Overlay img={c.cover} blur={showOver === `card-${idx}`} />
                  {showOver !== `card-${idx}` ? (
                    <CardContent>
                      <CardTitle>
                        <OpenSans
                          type="bodyLanding"
                          configuration={{
                            color: 'white',
                            fontWeight: 700,
                            fontSize: mediaIsPhone ? 16 : 20
                          }}
                        >
                          {c.title}
                        </OpenSans>
                      </CardTitle>
                      <CardBody>
                        <OpenSans
                          type="collectionCardBody"
                          configuration={mediaIsPhone ? { fontSize: 14 } : null}
                        >
                          <HtmlRaw
                            html={c.body_trimmed}
                            withEllipsis={c.body_trimmed !== c.body}
                          />
                        </OpenSans>
                      </CardBody>
                    </CardContent>
                  ) : (
                    <CardContent>
                      <CardTitle className="top">
                        <OpenSans
                          type="bodyLanding"
                          configuration={{ color: 'white', fontWeight: 700 }}
                        >
                          {c.title}
                        </OpenSans>
                      </CardTitle>
                      <CoursesContainer>
                        {c.lo?.length > 0 &&
                          c.lo.slice(0, 4).map((course, index) => (
                            <CourseWrapper key={`course-${index}`}>
                              {course.title.length > 35 ? (
                                <Tooltip
                                  text={course.title
                                    .replace(/&#039;/g, "'")
                                    .replace(/&quot;/g, '"')
                                    .trim()}
                                  customProps={{
                                    className: 'landing-carousel'
                                  }}
                                >
                                  <OpenSans
                                    type="infoBoxBody"
                                    configuration={{
                                      color: 'white',
                                      cursor: 'default',
                                      fontSize: mediaIsPhone ? 14 : 16
                                    }}
                                  >
                                    {course.title
                                      .replace(/&#039;/g, "'")
                                      .replace(/&quot;/g, '"')
                                      .substr(0, 35)
                                      .trim()}
                                    ...
                                  </OpenSans>
                                </Tooltip>
                              ) : (
                                <OpenSans
                                  type="infoBoxBody"
                                  configuration={{
                                    color: 'white',
                                    cursor: 'default',
                                    fontSize: mediaIsPhone ? 14 : 16
                                  }}
                                >
                                  {course.title
                                    .replace(/&#039;/g, "'")
                                    .replace(/&quot;/g, '"')
                                    .trim()}
                                </OpenSans>
                              )}
                              <Duration>
                                <IconPlaySmall className="icon-icons-play" />
                                <OpenSans type="courseDuration">
                                  {Math.round(course.duration / 60)} min
                                </OpenSans>
                              </Duration>
                            </CourseWrapper>
                          ))}
                      </CoursesContainer>
                      {/* {card.courses.length > 5 && (
                          <DotsWrapper>
                            <Dot />
                            <Dot />
                            <Dot />
                          </DotsWrapper>
                        )} */}
                    </CardContent>
                  )}
                </CollectionCard>
              ))}
            </CustomCarousel>
          </CarouselWrapper>
        </ContainerMain>
      </Container>
    </DigitalTrainingContainer>
  );
}

DigitalTraining.propTypes = {
  mediaIsPhone: PropTypes.bool,
  item: PropTypes.object,
  onClick: PropTypes.func,
  handleAutoPlay: PropTypes.func,
  autoPlay: PropTypes.bool,
  collections: PropTypes.array
};

export default connect(
  state => {
    const { autoPlay } = state.landingPage;
    return { autoPlay };
  },
  dispatch => ({
    handleAutoPlay: () => dispatch({ type: TOGGLE_AUTOPLAY })
  })
)(withMediaQueries(DigitalTraining));
