import styled from 'styled-components';
import { NavBarHeight, NavBarHeightTablet } from 'const';

export const PageContainer = styled.div`
  min-height: calc(100vh - ${NavBarHeight}px);
  width: 100%;
  color: ${props => props.theme.colors.greenDark};
  background-color: ${props => props.theme.colors.white};

  @media ${props => props.theme.device.tablet} {
    min-height: calc(100vh - ${NavBarHeightTablet}px);
  }
`;
