import styled from 'styled-components';
import { NavBarHeight } from '../../../../const';

export const FixedContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  overflow: hidden;
  background: ${props => props.theme.colors.primary.base};
  z-index: 999;
`;

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: ${NavBarHeight}px;
  width: 100%;
  position: relative;
`;

export const LogoContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;

export const Logo = styled.img`
  width: 170px;
  height: auto;
  cursor: pointer;
`;

export const Award = styled.img`
  width: auto;
  height: 75%;
  margin: 0 0 0 2%;
`;

export const MenuList = styled.ul`
  list-style-type: none;
  margin: 0 40px 0 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 100%;

  // &::before {
  //   content: '';
  //   display: block;
  //   height: ${NavBarHeight}px;
  //   width: 100vw;
  //   position: absolute;
  //   top: 0;
  //   left: 162px;
  //   background-color: ${props => props.theme.colors.amber};
  //   clip-path: polygon(50px 0%, 100% 0, 100% 100%, 0% 100%);
  // }
  //
  // &::after {
  //   content: '';
  //   display: block;
  //   height: ${NavBarHeight}px;
  //   width: 100vw;
  //   position: absolute;
  //   top: 0;
  //   left: 170px;
  //   background-color: ${props => props.theme.colors.primary.base};
  //   clip-path: polygon(50px 0%, 100% 0, 100% 100%, 0% 100%);
  // }
`;

export const MenuItem = styled.li`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  transition: all 0.5s ease 0s;
  text-align: center;
  &:not(:first-child) {
    margin-left: 40px;
  }
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.colors.white};
  z-index: 1;

  > span {
    cursor: pointer;
  }

  &:hover {
    &::after {
      content: '';
      display: block;
      height: 4px;
      width: 100%;
      background-color: ${props => props.theme.colors.white};
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
`;
export const Link = styled.a`
  &:link,
  :visited,
  :hover,
  :active {
    color: ${props => props.theme.colors.white};
  }
`;
