import styled, { css } from 'styled-components';
import { detailType } from '../../../utils/common';

const getStyleByType = propStyle => {
  let style;
  switch (propStyle.type) {
    case detailType.NEWS:
    case detailType.EVENTS:
      style = css`
        width: 290px;
        height: 350px;

        @media ${props => props.theme.device.laptop} {
          width: 290px;
          height: 350px;
        }

        @media ${props => props.theme.device.mobile} {
          width: 261px;
          height: 315px;
        }
      `;
      break;
    default:
      break;
  }

  return style;
};

export const ContentCardContainer = styled.div`
  width: 270px;
  height: 290px;
  box-shadow: 0 10px 19px 0 rgba(0, 0, 0, 0.2);
  background-color: ${props => props.theme.colors.white};
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 auto 20px;

  @media ${props => props.theme.device.mobile} {
    margin: ${props => (props.index === 0 ? '0 0 20px' : '0 auto 20px')};
  }

  ${props => getStyleByType(props)};
`;

export const ImgWrapper = styled.div`
  height: 130px;
  overflow: hidden;
  position: relative;
  width: 100%;
  cursor: ${props => (props.clickable ? 'pointer' : 'default')};

  @media ${props => props.theme.device.mobile} {
    height: ${props => (props.larger ? '115px' : '100px')};
  }
`;

export const Image = styled.img`
  position: absolute;
  display: block;
  width: 100%;
  height: auto;
  object-fit: cover;
`;

export const Footer = styled.div`
  width: 100%;
  height: 80px;
  padding: 15px 20px 20px;
`;

export const Text = styled.div`
  margin: 5px 0 0;
  & > div {
    display: inline-block;
  }
`;
export const TimeText = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonWrapper = styled.div`
  width: 135px;
  height: 55px;
  display: inline-block;
  position: absolute;
  bottom: -25px;
  display: flex;
  transform: translate(50%);
  @media ${props => props.theme.device.mobile} {
    width: 115px;
    height: 35px;
    bottom: -18px;
    transform: translate(0);
    left: calc((261px - 115px) / 2);
  }
`;
export const Title = styled.div`
  color: ${props => props.theme.colors.primary.base};
  margin-top: 5px;
`;

export const Play = styled.div`
  position: absolute;
  right: 15px;
  top: 125px;
  background-color: ${props => props.theme.colors.primary.base};
  color: ${props => props.theme.colors.white};
  line-height: 30px;
  text-align: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
`;

export const Icon = styled.span`
  font-size: 30px;
  margin: 20px;
  color: ${props =>
    props.index % 2 === 0
      ? props.theme.colors.primary.base
      : props.theme.colors.white};
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;

  &.small-icon {
    font-size: 18px;
  }

  &.align-left {
    margin-left: auto;
  }
`;
