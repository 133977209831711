import React from 'react';
import PropTypes from 'prop-types';
import { withMediaQueries } from 'hoc/withMediaQueries';
import { Container } from 'ui/gridSystem';
import {
  IntesaTraining
} from 'const';
import { connect } from 'react-redux';
import {
  FooterContainer,
  Copyright,
  CopyrightWrapper,
  Link,
  IconWrapper,
  FooterWrapper,
  Logo,
  Text,
  LegalLinks, LogoRight, LogoLeft
} from './style';
import OpenSans from '../../typography/openSans';

const Footer = ({ mediaIsPhone, isLanding, domain_settings }) => !domain_settings?.hidden_elements.hide_footer ? (
  <FooterContainer isLanding={isLanding} footer_color={domain_settings?.footer?.footer_color}>
    <Container>
      <FooterWrapper>
        <IconWrapper>
          <Logo>
            {domain_settings?.footer?.footer_left_logo && (
              <Link href={domain_settings?.footer?.footer_left_logo_link ? domain_settings?.footer?.footer_left_logo_link : IntesaTraining} target="_blank">
                <LogoRight src={domain_settings?.footer?.footer_left_logo} />
              </Link>
            )}
          </Logo>
          <Text>
            <OpenSans
              type="regular"
              configuration={{ color: 'white', fontSize: mediaIsPhone && 12 }}
            >
              {domain_settings?.footer?.footer_description ? domain_settings?.footer?.footer_description : ''}
            </OpenSans>
          </Text>
          {domain_settings?.footer?.footer_right_logo && (
            <Link href={domain_settings?.footer?.footer_right_logo_link ? domain_settings?.footer?.footer_right_logo_link : ''} target="_blank" style={{textDecoration: 'none'}}>
              <LogoLeft src={domain_settings?.footer?.footer_right_logo} />
            </Link>
          )}
        </IconWrapper>
        <CopyrightWrapper>
          <LegalLinks>
            <Link href={domain_settings?.link_cookies ? domain_settings?.link_cookies : ''} target="_blank">
              <OpenSans type="regular" configuration={{ fontSize: '12' }}>
                COOKIE POLICY
              </OpenSans>
            </Link>
            <Link href={domain_settings?.link_privacy ? domain_settings?.link_privacy : ''} target="_blank" className="spacer">
              <OpenSans type="regular" configuration={{ fontSize: '12' }}>
                PRIVACY POLICY
              </OpenSans>
            </Link>
          </LegalLinks>
          <Copyright>
            <OpenSans
              type="regular"
              configuration={{ fontSize: mediaIsPhone ? '10' : '12' }}
            >
              {domain_settings?.footer?.footer_copyright ? domain_settings?.footer?.footer_copyright : ''}
            </OpenSans>
          </Copyright>
        </CopyrightWrapper>
      </FooterWrapper>
    </Container>
  </FooterContainer>
) : <></>;

Footer.propTypes = {
  domain_settings: PropTypes.object
};

export default connect(state => ({
  domain_settings: state.domain.domain_settings
}))(withMediaQueries(Footer))
