export const getMenuLabel = id => {
  switch (id) {
    case 'offertaformativa':
      return 'Offerta Formativa';
    case 'newsedeventi':
      return 'Resta Informato';
    case 'filearticle':
      return 'Resta Informato';
    case 'storiedisuccesso':
      return 'Storie di Successo';
    case 'slidersp':
      return 'Le Iniziative del Gruppo';
    case 'contacts':
      return 'Contatti';
    default:
      return false;
  }
};

export const getMenuId = id => {
  switch (id) {
    case 'slidersp':
      return 'iniziative';
    case 'contacts':
      return 'contatti';
    default:
      return id;
  }
};

export const menuRefs = {
  TRAINING_OFFER: 'offertaformativa',
  NEWS_EVENTS: 'newsedeventi',
  EVENTS: 'events',
  FILE_ARTICLE: 'filearticle',
  CAROUSEL_SDS: 'storiedisuccesso',
  INITIATIVES: 'slidersp',
  CONTACTS: 'contacts'
};
