import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { PrevArrow, Icon, PrevArrowWrapper } from './style';

const CustomPrevArrow = ({ onClick, size, className }) => {
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (className.includes('slick-disabled')) setDisabled(true);
    else setDisabled(false);
  }, [className]);

  return (
    <PrevArrowWrapper
      size={size}
      margin={(document.body.offsetWidth - size) / 2 - 5}
      className={disabled ? 'disabled' : ''}
    >
      <PrevArrow onClick={onClick}>
        <Icon className="icon-btn-arrow" />
      </PrevArrow>
    </PrevArrowWrapper>
  );
};

CustomPrevArrow.propTypes = {
  onClick: PropTypes.func,
  size: PropTypes.number,
  className: PropTypes.string
};

export default CustomPrevArrow;
