/* eslint-disable react/jsx-key */
/* eslint-disable react/jsx-props-no-spreading */
import 'ui/scss/app.scss';
import 'ui/scss/bootstrap/bootstrap-grid.min.css';
import 'utils/modernizr';
import 'moment/locale/it';

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { Router, Route, Switch, Redirect } from 'react-router-dom';

import { history } from 'redux/store';

import { Loader } from 'ui/components';
import { ModalsPortal } from 'ui/components/Modals';
import theme from 'ui/theme';
import { LOADER_CLOSE, LOADER_OPEN } from 'redux/actions';
import { LandingPage } from 'pages';
import routes from 'routes';
import News from 'pages/News';
import { GET_DOMAIN, GET_FILTERED_DOMAIN_SETTINGS } from 'redux/actions/domain';

const App = ({
  brand,
  loaderOpen,
  loaderClose,
  getDomain,
  getFilteredDomainSetting,
  domain
}) => {
  const { hostname } = window.location;
  if (hostname.indexOf('.it') >= 0)
    window.location.href = 'https://www.skills4esg.com/';

  useEffect(() => {
    if (!domain?.id) {
      getDomain();
      getFilteredDomainSetting();
    }
    const titleElement = document.querySelector('title');
    const faviconElement = document.querySelector('link[rel="shortcut icon"]');
    if (domain?.domain_settings?.meta_title)
      titleElement.innerHTML = domain?.domain_settings?.meta_title;
    if (domain?.domain_settings?.favicon)
      faviconElement.href = domain?.domain_settings?.favicon;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domain]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brand]);

  const defaultColor = '#f2284f';

  loaderClose();
  if (!brand) {
    loaderOpen();
    return (
      <ThemeProvider theme={theme(brand?.color || defaultColor)}>
        <Loader isSpinning />
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme(brand?.color || defaultColor)}>
      <Router history={history}>
        <Switch>
          <Route
            path={routes.landingPage.path}
            exact
            render={() => <LandingPage />}
          />
          <Route path={`${routes.news.path}/:id`} render={() => <News />} />
          <Redirect to={routes.landingPage.path} />
        </Switch>
        <Loader />
        <ModalsPortal.Target />
      </Router>
    </ThemeProvider>
  );
};

App.propTypes = {
  // HOC (connect, state)
  getDomain: PropTypes.func.isRequired,
  getFilteredDomainSetting: PropTypes.func.isRequired,
  domain: PropTypes.object,
  brand: PropTypes.object,
  loaderOpen: PropTypes.func,
  loaderClose: PropTypes.func
};

export default connect(
  state => ({
    welcome: state.configuration,
    brand: state.landingPage.data.brand,
    domain: state.domain
  }),
  dispatch => ({
    getDomain: () => dispatch({ type: GET_DOMAIN._REQUEST }),
    getFilteredDomainSetting: () =>
      dispatch({ type: GET_FILTERED_DOMAIN_SETTINGS._REQUEST }),
    loaderOpen: () => dispatch({ type: LOADER_OPEN }),
    loaderClose: () => dispatch({ type: LOADER_CLOSE })
  })
)(App);
