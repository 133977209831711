import styled from 'styled-components';

export const ToolTipWrapper = styled.span`
  .__react_component_tooltip {
    padding: 15px 15px 24px;
    box-shadow: 0 10px 7px 0 rgba(50, 50, 50, 0.2);
    border: solid 1px #d6d6d6;
    width: auto;
    max-width: 300px;
    font-family: 'ProText';
    z-index: 10;
    min-width: ${props => (props.width ? props.width : 'auto')};

    &.landing-carousel {
      position: absolute;
      margin-bottom: 20px;
      left: auto !important;
      max-width: 244px;
      @media (max-width: 370px) {
        max-width: 224px;
      }
    }
  }
  /* .__react_component_tooltip.place-top::before {
    box-shadow: 0 10px 50px 0 rgba(50, 50, 50, 0.2);
  } */
  /* .__react_component_tooltip.place-top::after {
    box-shadow: 0 10px 50px 0 rgba(50, 50, 50, 0.2);
  } */
`;

export const ToolTipData = styled.span``;
