import styled, { css } from 'styled-components';
import Tabs from './index';

export const TabItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Icon = styled.div`
  font-size: 20px;
  margin-right: 5px;
`;
export const TabEL = styled.div`
  display: flex;
  font-weight: 400;
`;

const calculateStyle = ({ tabType }) => {
  let style;
  switch (tabType) {
    case Tabs.TYPE.PRIMARY:
      style = css`
        display: flex;
        ${TabItem} {
          min-height: 40px;
          padding: 12px 10px;
          font-family: ${props => props.theme.fontset.openSans};
          font-size: 20px;
          color: ${props => props.theme.colors.black};
          font-weight: 500;
          cursor: pointer;
          text-align: center;
          margin-right: 10px;
          display: flex;
          flex: none;

          &.active {
            color: ${props => props.theme.colors.primary.base};
            position: relative;
            
            &:after {
              content: '';
              display: block;
              height: 3px;
              width: 100%;
              position: absolute;
              left: 0;
              bottom: 0;
              background-color: ${props => props.theme.colors.primary.base};
            }
          }
        }
      `;
      break;
    case Tabs.TYPE.SECONDARY:
    default:
      style = css`
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: flex-end;
        position: relative;
        overflow: auto;
        padding-bottom: 7px;
        flex-wrap: wrap;
        overflow: hidden;
        justify-content: center;
        color: gray;

        &::after {
          content: '';
          height: 1px;
          width: 90%;
          background-color: lightgrey;
          position: absolute;
          /*                     bottom: 4px;
 */
        }

        ${TabItem} {
          position: relative;
          display: inline-block;
          cursor: pointer;
          width: fit-content;
          margin-top: 10px;
          padding: 20px;
          white-space: nowrap;
          z-index: 1;

          &:after {
            content: '';
            display: block;
            width: 100%;
            height: 5px;
            position: absolute;
            left: 0;
            bottom: -3px;
            background-color: transparent;
            transition: 0.2s ease-in;
          }

          &.active {
            color: #014167;
            font-weight: 500;
            &:after {
              background-color: #014167;
            }
          }
        }
      `;
      break;
  }

  return style;
};

export const TabsContainer = styled.div`
  ${props => calculateStyle(props)};
  flex-direction: row;
  overflow-x: auto;
  &:after {
    content: '';
    margin-right: 20px;
  }
  /* background-color: #f2f6fb; */
`;
