import mockLandingPage from './mockLandingPage.json';
import mockDomainSettings from './domainSettings.json';

export function shouldUseMock() {
  return (
    process.env.REACT_APP_DEPLOY_ENV === 'LOCAL' &&
    Number(process.env.REACT_APP_ENABLE_MOCK) === 1
  );
}

const lowecaseDataKeys = data =>
  // eslint-disable-next-line no-sequences
  Object.keys(data).reduce((c, k) => ((c[k.toLowerCase()] = data[k]), c), {});

const getBaseResponse = (data, status = 200, message) => ({
  status,
  data: lowecaseDataKeys(data),
  message
});

export const getDomainMock = () =>
  getBaseResponse({
    data: [
      {
        id: 1030,
        title: 'ESG',
        url: 'd3ejmj0viet33z.cloudfront.net',
        cohort_id: 26,
        email: '',
        image_login: '',
        brand: [
          {
            name: 'ESG',
            color: '#f2284f',
            logo: '',
            logo_white: '',
            logo_rgb:
              'https://d22cxfqtum6m93.cloudfront.net/media/2021-07/esg-logo-e-1.png?VersionId=pLQbBW9h.9uRNynHnGENnjV0z6vOu35k'
          }
        ],
        multi_channel: 'web',
        contract: '',
        policy: '',
        consents: '',
        cookies_description: ''
      }
    ],
    pager: {
      current_page: 0,
      total_items: '1',
      total_pages: 1,
      items_per_page: 10
    },
    count_data_obj: '1'
  });

export const getLandingPageMock = () => getBaseResponse(mockLandingPage);
export const getFilteredDomainSettingsMock = () =>
  getBaseResponse(mockDomainSettings);
