import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { NextArrow, Icon, NextArrowWrapper } from './style';

const CustomNextArrow = ({ onClick, size, className }) => {
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (className.includes('slick-disabled')) setDisabled(true);
    else setDisabled(false);
  }, [className]);

  return (
    <NextArrowWrapper
      size={size}
      margin={(document.body.offsetWidth - size) / 2 - 5}
      className={disabled ? 'disabled' : ''}
    >
      <NextArrow onClick={onClick}>
        <Icon className="icon-btn-arrow" />
      </NextArrow>
    </NextArrowWrapper>
  );
};

CustomNextArrow.propTypes = {
  onClick: PropTypes.func,
  size: PropTypes.number,
  className: PropTypes.string
};

export default CustomNextArrow;
