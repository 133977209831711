import React, { useState, useEffect } from 'react';
import { push } from 'redux-first-history';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import routes from 'routes';
import { SCROLL_TO } from 'redux/actions/landingPage';

import { withMediaQueries } from '../../../hoc/withMediaQueries';
import DesktopNavigationMenu from './desktop';
import MobileNavigationMenu from './mobile';

const Index = ({ paragraphs, mediaIsPhone, mediaIsTablet, pushUrl, scrollToRef }) => {
  const [menu, setMenu] = useState([]);

  const onClickMenu = id => {
    const isFromNews = window.location.href.includes('news');
    pushUrl(routes.landingPage.path);
    setTimeout(() => scrollToRef(id, isFromNews), 10);
  };

  useEffect(() => {
    const formattedMenu = paragraphs.reduce((acc, val) => {
      const label = val.type === 'File Article' ? val.type : val.title || val.type;
      const value = val.type === 'File Article' ? val.type.replace(/\s/g, '').toLowerCase() : val.title.replace(/\s/g, '').toLowerCase() || val.type.replace(/\s/g, '').toLowerCase();
      const item = {
        label,
        value,
        onClick: () => onClickMenu(value)
      };
      return [...acc, item];
    }, []);
    setMenu(formattedMenu);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paragraphs]);

  if (mediaIsPhone || mediaIsTablet) {
    return <MobileNavigationMenu menu={menu} />;
  }
  const htmlElement = document.querySelector('body');
  htmlElement.classList.remove('disabled-scroll');
  return <DesktopNavigationMenu menu={menu} />;
};

Index.propTypes = {
  scrollToRef: PropTypes.func,
  pushUrl: PropTypes.func,
  // HOC (connect, state)
  paragraphs: PropTypes.array.isRequired,
  // HOC (withMediaQueries)
  mediaIsPhone: PropTypes.bool.isRequired,
  mediaIsTablet: PropTypes.bool.isRequired
};

export default connect(
  state => {
    const {
      landingPage: {
        data: { paragraphs = [] }
      }
    } = state;
    return {
      paragraphs
    };
  },
  dispatch => ({
    pushUrl: url => dispatch(push(url)),
    scrollToRef: (id, isFromNews) =>
      dispatch({ type: SCROLL_TO, id, isFromNews })
  })
)(withMediaQueries(Index));
