import styled from 'styled-components';

export const PlaceHolder = styled.div`
  padding: 110px 15px 21px 16px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='9' ry='9' stroke='lightgrey' stroke-width='3' stroke-dasharray='16' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 14px;
  background-color: rgba(255, 255, 255, 0.66);
  display: flex;
  flex-wrap: wrap;
`;

export const Rect1Ph = styled.div`
  width: 160px;
  height: 14px;
  margin: 0 0 6px;
  border-radius: 7px;
  background-color: #e8e8ea;
`;
export const Rect2Ph = styled.div`
  width: 105px;
  height: 14px;
  margin: 6px 55px 7px 0;
  border-radius: 7px;
  background-color: #e8e8ea;
`;
export const Boul1Ph = styled.div`
  width: 18px;
  height: 18px;
  margin: 7px 3px 0 0;
  opacity: 0.5;
  border-radius: 10px;
  background-color: #e8e8ea;
`;
export const Boul2Ph = styled.div`
  width: 18px;
  height: 10px;
  margin: 11px 121px 4px 3px;
  opacity: 0.5;
  border-radius: 10px;
  background-color: #e8e8ea;
`;
