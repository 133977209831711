import React from 'react';
import PropTypes from 'prop-types';
import 'react-multi-carousel/lib/styles.css';
import { withMediaQueries } from 'hoc/withMediaQueries';
import Slider from '../Slider';
import DigitalTraining from '../DigitalTraining';
import CarouselSDS from '../CarouselSDS';
import Contacts from '../Contacts';
import TrainingOffer2 from '../TrainingOffer2';
import Events from '../Events';
import FileArticle from '../FileArticle';

const RenderLandingParagraph = ({ item, onClick }) => {
  switch (item.type) {
    case 'Primo slider':
      return <Slider cards={item?.cards_primo_slider} onClick={onClick} />;

    case 'Offerta Formativa':
      return (
        <TrainingOffer2
          cards={item?.cards_offerta_formativa}
          onClick={onClick}
        />
      );
    case 'Banner Formazione Digitale':
      return (
        <DigitalTraining
          onClick={onClick}
          item={item}
          collections={item?.collections?.data}
        />
      );
    case 'File Article':
      return <FileArticle data={item} hasTitle={item.hasTitle} />;
    case 'Events':
      return <Events cards={item?.data} hasTitle={item.hasTitle} />;
    // case 'News ed Eventi':
    //   return <NewsEvents cards={item} />;
    case 'Carousel SDS':
      return (
        <CarouselSDS onClick={onClick} item={item} cards={item?.cards_sds} />
      );
    case 'Contacts':
      return <Contacts item={item} />;
    case 'Slider SP':
      return (
        <Slider cards={item?.cards_slider_sp} isLastSlider onClick={onClick} />
      );
    default:
      return <></>;
  }
};

RenderLandingParagraph.propTypes = {
  // HOC (connect, state)
  item: PropTypes.object,
  onClick: PropTypes.func
};

export default withMediaQueries(RenderLandingParagraph);
