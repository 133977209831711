import styled from 'styled-components';

export const ContactsContainer = styled.div`
  width: 100%;
  min-height: 326px;
  height: fit-content;
  padding: 50px 0;

  @media ${props => props.theme.device.mobile} {
    padding: 40px 0;
  }
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 30px 0;
`;

export const MailWrapper = styled.a`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  > span {
    margin-left: 10px;
    border-bottom: 1px solid ${props => props.theme.colors.jet};

    @media ${props => props.theme.device.mobile} {
      font-size: 14px;
    }
  }
`;
export const PhoneWrapper = styled.a`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 19px 0;

  > span {
    margin-left: 10px;

    @media ${props => props.theme.device.mobile} {
      font-size: 14px;
    }
  }
`;
export const IconContainer = styled.div`
  width: 43px;

  @media ${props => props.theme.device.mobile} {
    width: 33px;
  }
`;
export const IconImg = styled.img``;
export const InfoWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
