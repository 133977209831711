import React from 'react';
import PropTypes from 'prop-types';
import { FontBase } from 'const';
import Typeface, { TypefaceInterface } from './index';

const baseConfig = {
  color: 'unset',
  fontFamily: 'openSans',
  fontStyle: 'normal',
  fontWeight: 'normal',
  lineHeight: 1,
  letterSpacing: 'unset',
  fontSize: FontBase
};

const types = {
  light: {
    ...baseConfig,
    fontWeight: 300
  },
  regular: {
    ...baseConfig
  },
  italic: {
    ...baseConfig,
    fontStyle: 'italic'
  },
  semibold: {
    ...baseConfig,
    fontWeight: 600
  },
  bold: {
    ...baseConfig,
    fontWeight: 700
  },
  link: {
    fontSize: 16,
    fontWeight: 700
  },
  playlistOvertitle: {
    ...baseConfig,
    fontSize: 12,
    lineHeight: 1.17,
    letterSpacing: '-0.1px'
  },
  playlistTitle: {
    ...baseConfig,
    siez: 18,
    letterSpacing: '-0.13px'
  },
  newsTitle: {
    ...baseConfig,
    fontSize: 20,
    fontWeight: 700
  },
  newsBody: {
    ...baseConfig,
    fontSize: 14,
    lineHeight: 1.57,
  },
  clockLabel: {
    ...baseConfig,
    fontSize: 14,
    lineHeight: 1.14,
  },
  navigationMenuLink: {
    ...baseConfig,
    fontSize: 20,
    lineHeight: 1.25,
    color: 'primary',
    colorType: 'base'
  },
  checkboxLabel: {
    ...baseConfig,
    fontSize: 14,
    lineHeight: 1.14,
  },
  carouselCardTitle: {
    ...baseConfig,
    fontSize: 18,
    lineHeight: 1.22,
    letterSpacing: '0.29px',
    color: 'primary'
  },
  carouselCardBody: {
    ...baseConfig,
    fontSize: 16,
    lineHeight: 1.25,
    letterSpacing: '0.25px',
    color: 'softGray'
  },
  carouselCardTime: {
    ...baseConfig,
    fontSize: 12,
    lineHeight: 1.67,
    color: 'softGray'
  },
  landingtitle: {
    ...baseConfig,
    fontSize: 25,
    color: 'primary',
    colorType: 'base'
  },
  bodyLanding: {
    ...baseConfig,
    fontSize: 25,
    lineHeight: 1.2,
    color: 'mineShaft'
  },
  sliderTitle: {
    ...baseConfig,
    fontSize: 40,
    fontWeight: 600,
    lineHeight: 'normal',
    color: 'white'
  },
  sliderSubtitle: {
    ...baseConfig,
    fontSize: 20,
    fontWeight: 300,
    lineHeight: 'normal',
    color: 'white'
  },
  paragraphTitle: {
    ...baseConfig,
    fontSize: 30,
    fontWeight: 600,
    color: 'primary',
    colorType: 'base'
  },
  sdsTitle: {
    fontSize: 25,
    fontWeight: 600,
    color: 'jet'
  },
  digitalTraining: {
    ...baseConfig,
    fontSize: 18,
    lineHeight: 1.5,
    color: 'jet'
  },
  totalCost: {
    ...baseConfig,
    fontSize: 30,
    fontWeight: 600,
    color: 'primary',
    colorType: 'base'
  },
  digitalTrainingTitle: {
    ...baseConfig,
    fontSize: 25,
    fontWeight: 600,
    lineHeight: 0.83,
    color: 'jet'
  },
  contactsDescription: {
    ...baseConfig,
    fontSize: 20,
    fontWeight: 600,
    lineHeight: 1.1,
    letterSpacing: '0.33px',
    color: 'jet',
    textAlign: 'center'
  },
  contactsEmail: {
    ...baseConfig,
    lineHeight: 1.5,
    color: 'jet'
  },
  contactsPhone: {
    ...baseConfig,
    lineHeight: 1.5,
    color: 'jet'
  },
  contactsSubtitle: {
    ...baseConfig,
    fontSize: 15,
    lineHeight: 1.5,
    color: 'jet',
    textAlign: 'center'
  },
  modalTitle: {
    ...baseConfig,
    fontSize: 23,
    fontWeight: 600,
    color: 'primary',
    colorType: 'base'
  },
  progressCardTitle: {
    ...baseConfig,
    fontSize: 25,
    fontWeight: 700,
    lineHeight: '1.2',
    color: 'white'
  },
  progressCardTitle2: {
    ...baseConfig,
    fontSize: 24,
    lineHeight: '37px',
    color: 'white'
  },
  infoBoxTitle: {
    ...baseConfig,
    fontSize: 25,
    lineHeight: 1.3,
    letterSpacing: '0.32px',
    color: 'primary',
    colorType: 'base'
  },
  collectionCardBody: {
    ...baseConfig,
    fontSize: 18,
    lineHeight: '1.2',
    letterSpacing: '0.32px',
    color: 'white'
  },
  infoBoxBody: {
    ...baseConfig,
    fontSize: 16,
    lineHeight: 1.2,
    letterSpacing: '0.25px',
    color: 'jet'
  },
  infoBoxBody2: {
    ...baseConfig,
    fontSize: 14,
    lineHeight: '23px',
    letterSpacing: '0.2px',
    color: 'white'
  },
  courseDuration: {
    ...baseConfig,
    fontSize: 12,
    fontWeight: 600,
    lineHeight: 1.2,
    letterSpacing: '-0.19px',
    color: 'timeGray'
  },
};

const OpenSans = ({ type, configuration, children, className }) => (
  <Typeface className={className} configuration={{ ...types[type], ...configuration }}>
    {children}
  </Typeface>
);

OpenSans.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  type: PropTypes.oneOf(Object.keys(types)),
  configuration: TypefaceInterface
};

OpenSans.defaultProps = {
  type: 'regular'
};

export default OpenSans;
