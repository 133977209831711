import styled from 'styled-components';
import { NavBarHeight, NavBarHeightMobile } from '../../const';

export const Background = styled.div`
  background-color: ${props => props.theme.colors.mineShaft};
  z-index: -10;
`;

export const Title = styled.div`
  padding-bottom: 14px;
  padding-top: 30px;
  word-break: break-word;

  color: ${props => props.theme.colors.primary.base};

  @media ${props => props.theme.device.mobile} {
    padding-bottom: 5px;
  }
`;

export const Text = styled.div`
  padding-bottom: 48px;
  padding-right: 60px;
  color: ${props => props.theme.colors.greenDark};

  @media ${props => props.theme.device.tablet} {
    padding-bottom: 27px;
  }
  @media ${props => props.theme.device.mobile} {
    padding-right: 0;
    padding-bottom: 23px;
  }
`;

export const Buttons = styled.div`
  display: inline-flex;
  @media ${props => props.theme.device.mobile} {
    display: flex;
    flex-direction: column-reverse;
  }
`;

export const Image = styled.img`
  position: relative;
  top: 0;
  right: 0;

  max-width: 100%;
  height: auto;
  max-height: 90vh;
  z-index: 0;
`;

export const Head = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const RowStripe = styled.div`
  position: relative;
`;

export const Placeholder = styled.div`
  width: 100%;
  height: 100vh;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center center;
`;

export const Layout = styled.div`
  background-image: linear-gradient(
    -45deg,
    rgba(187, 58, 54, 0.8),
    rgba(234, 129, 102, 0.4)
  );
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const MainContent = styled.div`
  padding-top: ${NavBarHeight}px;
  
  @media ${props => props.theme.device.tablet} {
    padding-top: ${NavBarHeightMobile}px;
  }
`;
