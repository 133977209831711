import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withMediaQueries } from 'hoc/withMediaQueries';
import { Container } from 'ui/gridSystem';
import { menuRefs } from 'utils/menu';

import {
  ContactsContainer,
  DescriptionWrapper,
  IconContainer,
  IconImg,
  InfoWrapper,
  MailWrapper,
  PhoneWrapper
} from './style';
import IconMail from '../../assets/img/icon-mail.png';
import IconPhone from '../../assets/img/icon-phone.png';
import OpenSans from '../../typography/openSans';

function Contacts({ mediaIsPhone, item, scrollToRef, scrollCount }) {
  const myRef = useRef(null);

  useEffect(() => {
    if (scrollToRef === menuRefs.CONTACTS) {
      myRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [scrollToRef, scrollCount]);

  return (
    <ContactsContainer ref={myRef}>
      <Container>
        <DescriptionWrapper>
          <OpenSans
            type="contactsDescription"
            configuration={mediaIsPhone ? { fontSize: 16 } : {}}
          >
            Per saperne di più sulla nostra attività
          </OpenSans>
        </DescriptionWrapper>
        <MailWrapper href={`mailto:${item.data[0]?.email}`}>
          <IconContainer>
            <IconImg src={IconMail} />
          </IconContainer>
          <OpenSans type="contactsEmail">{item.data[0]?.email}</OpenSans>
        </MailWrapper>
        <DescriptionWrapper>
          <OpenSans
            type="contactsDescription"
            configuration={mediaIsPhone ? { fontSize: 16 } : {}}
          >
            {/* {item?.description} */}
            Per informazioni sui percorsi formativi di Academy per le Imprese,
            supporto tecnico e amministrativo
          </OpenSans>
        </DescriptionWrapper>
        <MailWrapper href={`mailto:${item.data[1]?.email}`}>
          <IconContainer>
            <IconImg src={IconMail} />
          </IconContainer>
          <OpenSans type="contactsEmail">{item.data[1]?.email}</OpenSans>
        </MailWrapper>
        <PhoneWrapper href={`tel:${item.data[1]?.phone}`}>
          <IconContainer>
            <IconImg src={IconPhone} />
          </IconContainer>
          <OpenSans type="contactsPhone">{item.data[1]?.phone}</OpenSans>
        </PhoneWrapper>
        <InfoWrapper>
          <OpenSans
            type="contactsSubtitle"
            configuration={mediaIsPhone ? { fontSize: 12 } : {}}
          >
            (attivo dal lunedì al venerdì dalle ore 9.00 alle ore 13.00 e dalle
            ore 14.00 alle ore 18.00)
            {/* {item?.subtitle} */}
          </OpenSans>
        </InfoWrapper>
      </Container>
    </ContactsContainer>
  );
}

Contacts.propTypes = {
  item: PropTypes.object,
  scrollToRef: PropTypes.string,
  scrollCount: PropTypes.number,
  mediaIsPhone: PropTypes.bool
};

export default connect(state => {
  const { scrollToRef, scrollCount } = state.landingPage;
  return { scrollToRef, scrollCount };
})(withMediaQueries(Contacts));
