import styled, { css } from 'styled-components';

export const Placeholder = styled.span`
  color: ${props => props.theme.colors.placeholder};
  position: absolute;
  left: 20px;
  transition: 125ms ease-in;
  pointer-events: none;

  > div {
    display: inline-block;
    width: auto;
    transition: font-size 250ms ease-in;
  }
`;

export const Error = styled.span`
  color: ${props => props.theme.colors.redError};
  display: block;
  margin: 8px 0 0px;
`;

export const Valid = styled.span`
  display: block;
  margin: 8px 0 0px;
`;

export const Input = styled.input`
  border-radius: 4px;
  border: solid 1px ${props => props.theme.colors.darkGray};
  background-color: ${props => props.theme.colors.white};
  height: 100%;
  width: 100%;
  padding: 12px 20px 0;
  transition: all 125ms ease-in;

  text-transform: ${props => (props.forceUppercase ? 'uppercase' : 'none')};

  ${props =>
    props.disabled &&
    css`
      background-color: rgba(232, 232, 234, 0.2);
    `};

  &:focus {
    outline: none;
    appearance: none;
    border-color: ${props => props.theme.colors.azureDark};
    outline: 0;
    box-shadow: 0 0 0 0.17647rem
      ${props => props.theme.mixin.rgba(props.theme.colors.azureDark, 0.2)};
  }

  &::-moz-placeholder {
    line-height: 33px;
  }
`;

export const InputContainer = styled.div`
  height: 55px;
  position: relative;

  ${Placeholder} {
    ${props =>
      !(props.focus || props.value || props.forceFocus)
        ? css`
            top: 50%;
            transform: translate(0, -50%);
          `
        : css`
            top: 2px;
          `};
  }

  ${props =>
    props.error &&
    css`
      ${Input} {
        border-color: ${!props.focus && props.theme.colors.redError};
        color: ${!props.focus && props.theme.colors.redError};
      }
    `};

  .StripeElement {
    border-radius: 4px;
    border: solid 1px ${props => props.theme.colors.darkGray};
    background-color: ${props => props.theme.colors.white};
    height: 100%;
    width: 100%;
    padding: 24px 0 0 20px;
    transition: all 125ms ease-in;

    &--invalid {
      border-color: ${props => !props.focus && props.theme.colors.redError};
    }

    &--focus {
      outline: none;
      appearance: none;
      border-color: ${props => props.theme.colors.azureDark};
      outline: 0;
      box-shadow: 0 0 0 0.17647rem
        ${props => props.theme.mixin.rgba(props.theme.colors.azureDark, 0.2)};
    }
  }
`;

export const SpanIcon = styled.span`
  position: absolute;
  font-size: x-large;
  color: ${props => props.theme.colors.lighterGray};
  top: 14px;
  right: 35px;
  cursor: pointer;
`;
