import React from 'react';
import PropTypes from 'prop-types';

import { Btn, Icon, IconStore } from './style';

const Button = ({
  name,
  id,
  children,
  disabled,
  url,
  text,
  isDownload,
  onClick,
  hover,
  hasIcon,
  type,
  active,
  height,
  fontSize,
  mediaIsPhone
}) => (
  <Btn
    name={name || id || `btn-${type}`}
    id={id}
    onClick={onClick}
    href={url}
    isDownload={isDownload}
    disabled={disabled}
    hover={hover}
    hasIcon={hasIcon}
    type={type}
    active={active}
    height={height}
    fontSize={mediaIsPhone ? 12 : fontSize}
  >
    {isDownload ? (
      <IconStore className="icon-app-store" />
    ) : (
      <>
        {text?.toUpperCase() || children}
        {hasIcon && <Icon className="icon-btn-arrow" />}
      </>
    )}
  </Btn>
);

Button.TYPE = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
  TERTIARY: 'TERTIARY',
  FILTER: 'FILTER',
  STORE: 'STORE',
  LANDING: 'LANDING',
  TEAMS: 'TEAM'
};

Button.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  url: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  text: PropTypes.string,
  isDownload: PropTypes.bool,
  onClick: PropTypes.func,
  hover: PropTypes.bool,
  hasIcon: PropTypes.bool,
  active: PropTypes.bool,
  type: PropTypes.oneOf([
    Button.TYPE.PRIMARY,
    Button.TYPE.FILTER,
    Button.TYPE.SECONDARY,
    Button.TYPE.TERTIARY,
    Button.TYPE.STORE,
    Button.TYPE.LANDING
  ]),
  height: PropTypes.number,
  fontSize: PropTypes.number,
  mediaIsPhone: PropTypes.bool
};

Button.defaultProps = {
  type: Button.TYPE.DEFAULT,
  height: 55,
  fontSize: 14
};

export default Button;
