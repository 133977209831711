import { desaturateColor, shadeColor } from 'utils/common';

const colors = themeColor => ({
  /* Main */
  white: '#ffffff',
  black: '#000000',
  transparent: 'transparent',
  logo: '#595959',

  /* Primary */
  primary: {
    base: themeColor,
    darker: '#000000', //shadeColor(themeColor, -40),
    lighter: '#000000' //desaturateColor(shadeColor(themeColor, 40), 50)
  },

  /* Secondary */
  orange: '#D35C44',
  red: '#B1443D',
  redError: '#DE071C',
  greenDark: '#182A32',
  azureDark: '#0B3A4E',
  blueDark: '#315564',
  mineShaft: '#333333',
  filterColor: '#e8edef',
  placeholder: '#888888',
  amber: '#fcbe0e',

  /* Grey */
  lighterGray: '#F8F9FA',
  middleLightGray: '#F1F1F1',
  lightGray: '#8e8e93',
  darkGray: '#d6d6d6',
  darkerGray: '#708090',
  timeGray: '#e3e3e3',
  jet: '#323232',
  davysGray: '#5c5c5c',
  tinyLightGray: '#cecece',
  softGray: '#525252',

  linearGradient: 'linear-gradient( 258deg, #e9dc9f, #90e4c7)',

  /* Shadow */
  borderShadow: '0 0 10px 0 rgba(0,0,0,0.01), 0 5px 20px 0 rgba(0,0,0,0.08)',

  /* Icon */
  lightGreen: '#6fd22e',
  darkGreen: '#2b8804'
});

export default colors;
