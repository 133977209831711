import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const TypefaceInterface = PropTypes.shape({
  color: PropTypes.string,
  fontFamily: PropTypes.string,
  fontStyle: PropTypes.oneOf(['normal', 'italic']),
  fontWeight: PropTypes.oneOf([
    100,
    200,
    300,
    400,
    500,
    600,
    700,
    900,
    'normal',
    'bold',
    'light',
    'medium'
  ]),
  lineHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  letterSpacing: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.number
});

const defaultConfig = {
  color: 'unset',
  fontFamily: 'openSans',
  fontStyle: 'normal',
  fontWeight: 'normal',
  lineHeight: 1,
  letterSpacing: 'unset',
  fontSize: 12,
  textAlign: 'unset',
  textTransform: 'unset',
  cursor: 'auto'
};

export const TypefaceStyled = styled.span`
  ${props => `
    color: ${
      props.config.colorType
        ? props.theme.colors[props.config.color]?.[props.config.colorType]
        : props.theme.colors[props.config.color]
    };
    font-family: ${props.theme.fontset[props.config.fontFamily]};
    font-size: ${props.config.size || props.config.fontSize}px;
    font-style: ${props.config.fontStyle};
    font-weight: ${props.config.fontWeight};
    letter-spacing: ${props.config.letterSpacing};
    line-height: ${props.config.lineHeight};
    text-align: ${props.config.textAlign};
    text-transform: ${props.config.textTransform};
    text-decoration: ${props.config.textDecoration};
    cursor: ${props.config.cursor};
  `}
`;

const Typeface = ({ configuration, children, className }) => (
  <TypefaceStyled className={className} config={{ ...defaultConfig, ...configuration }}>
    {children}
  </TypefaceStyled>
);

Typeface.propTypes = {
  children: PropTypes.node,
  configuration: TypefaceInterface
};

export default Typeface;
