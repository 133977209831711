import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import routes from 'routes';

import { withMediaQueries } from 'hoc/withMediaQueries';
import { detailType } from 'utils/common';
import { Button } from 'ui/atoms';
import {
  ContentCardContainer,
  ImgWrapper,
  Image,
  Footer,
  Text,
  Play,
  Icon,
  Title,
  ButtonWrapper,
  TimeText
} from './style';
import HtmlRaw from '../HtmlRaw';
import OpenSans from '../../typography/openSans';

const ContentCard = ({ mediaIsPhone, type, data, onClick, index, pushUrl }) => {
  const enToIt = {
    Jan: 'Gennaio',
    Feb: 'Febbraio',
    Mar: 'Marzo',
    Apr: 'Aprile',
    May: 'Maggio',
    Jun: 'Giugno',
    Jul: 'Luglio',
    Aug: 'Agosto',
    Sep: 'Settembre',
    Oct: 'Ottobre',
    Nov: 'Novembre',
    Dec: 'Dicembre'
  };
  const translateDate = dateString => {
    const dateArray = dateString.split(' ');
    const date = dateArray[0].split('-');
    const month = enToIt[date[1]];
    date[1] = month;
    const newDate = date.join(' ');
    return newDate;
  };

  const getHour = dateString => {
    const dateArray = dateString.split(' ');
    const hour = dateArray[1];
    return hour;
  };

  const getContentByType = typeContent => {
    switch (typeContent) {
      case detailType.EVENTS:
        return (
          <>
            <ImgWrapper>
              <Image src={data.image} larger />
            </ImgWrapper>

            {data.format === 'video' && <Play className="icon-play" />}
            <Footer>
              <Title>
                <OpenSans
                  type="carouselCardTitle"
                  configuration={mediaIsPhone ? { fontSize: 16 } : null}
                >
                  {data.title.slice(0, 50)}
                  {data.title.length > 50 && '...'}
                </OpenSans>
              </Title>
              <Text>
                <OpenSans
                  type="carouselCardBody"
                  configuration={mediaIsPhone ? { fontSize: 14 } : null}
                >
                  <HtmlRaw html={data.body} />
                </OpenSans>
              </Text>
            </Footer>
            <ButtonWrapper>
              <Button
                text={'Scopri di più'.toUpperCase()}
                type={Button.TYPE.PRIMARY}
                onClick={() => window.open(data.cta_1_url, '_blank')}
                mediaIsPhone={mediaIsPhone}
              />
            </ButtonWrapper>
          </>
        );
      case detailType.NEWS:
        return (
          <>
            <ImgWrapper
              onClick={() => {
                pushUrl(`${routes.news.path}/${data.id}`);
              }}
              clickable
            >
              <Image src={data.cover} />
            </ImgWrapper>
            <Icon onClick={() => {
              pushUrl(`${routes.news.path}/${data.id}`);
            }} className="icon-arrow-right" index={index} />
            <Footer>
              <TimeText>
                <OpenSans type="carouselCardTime">
                  {' '}
                  {translateDate(data.created)}
                </OpenSans>
                <OpenSans type="carouselCardTime">
                  {' '}
                  {getHour(data.created)}
                </OpenSans>
              </TimeText>
              <Title>
                <OpenSans
                  type="carouselCardTitle"
                  configuration={mediaIsPhone ? { fontSize: 16 } : null}
                >
                  {data.title.slice(0, 50)}
                  {data.title.length > 50 && '...'}
                </OpenSans>
              </Title>
              <Text>
                <OpenSans
                  type="carouselCardBody"
                  configuration={mediaIsPhone ? { fontSize: 14 } : null}
                >
                  <HtmlRaw
                    html={data.body_trimmed}
                    withEllipsis={data.body_trimmed !== data.body}
                  />
                </OpenSans>
              </Text>
            </Footer>
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <ContentCardContainer
      type={type}
      img={data.cover}
      onClick={onClick}
      index={index}
    >
      {getContentByType(type)}
    </ContentCardContainer>
  );
};

ContentCard.propTypes = {
  data: PropTypes.object,
  type: PropTypes.string,
  onClick: PropTypes.func,
  index: PropTypes.number,
  pushUrl: PropTypes.func,
  //HOC (connect, dispatch)

  //HOC withMediaQueries
  mediaIsPhone: PropTypes.bool
};

export default connect(
  () => ({}),
  dispatch => ({
    pushUrl: url => dispatch(push(url))
  })
)(withMediaQueries(ContentCard));
