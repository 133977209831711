import React, {
  useEffect, useRef
} from 'react';
import PropTypes from 'prop-types';
import { withMediaQueries } from 'hoc/withMediaQueries';
import { menuRefs } from 'utils/menu';
import { connect } from 'react-redux';

import { Container } from 'ui/gridSystem';
import {
  FileArticleContainer,
  Title,
  FileArticleHeader
} from './style';
import OpenSans from '../../typography/openSans';
import FileArticleCard from '../FileArticleCard';

// eslint-disable-next-line no-unused-vars
function FileArticle({ mediaIsPhone, hasTitle, data, scrollToRef, scrollCount }) {
  const myRef = useRef(null);

  useEffect(() => {
    if (hasTitle && scrollToRef === menuRefs.FILE_ARTICLE) {
      myRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [scrollToRef, scrollCount, hasTitle]);

  return (
    <FileArticleContainer ref={myRef} hasTitle={hasTitle}>
      <Container className={mediaIsPhone ? 'p-0' : ''}>
        <FileArticleHeader>
          {hasTitle && (
          <Title>
            <OpenSans
              type="paragraphTitle"
              configuration={mediaIsPhone ? { fontSize: 20 } : null}
            >
              Resta informato
            </OpenSans>
          </Title>
        )}
        </FileArticleHeader>
        <FileArticleCard data={data} />
      </Container>
    </FileArticleContainer>
  );
}

FileArticle.propTypes = {
  mediaIsPhone: PropTypes.bool,
  hasTitle: PropTypes.bool,
  data: PropTypes.object,
  scrollToRef: PropTypes.string,
  scrollCount: PropTypes.number
};

export default connect(state => {
  const { scrollToRef, scrollCount } = state.landingPage;
  return { scrollToRef, scrollCount };
})(withMediaQueries(FileArticle));
