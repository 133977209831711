import React, {
  useEffect, useRef, useState, useLayoutEffect
} from 'react';
import PropTypes from 'prop-types';
import { withMediaQueries } from 'hoc/withMediaQueries';
import { menuRefs } from 'utils/menu';
import { connect } from 'react-redux';

import { Container } from 'ui/gridSystem';
import {
  NewsEventsMobileSettings,
  NewsEventsSettings
} from 'utils/slider-settings';
import Tabs from 'ui/atoms/Tabs';
import { detailType } from 'utils/common';
import ContentCard from '../ContentCard';
import CustomCarousel from '../CustomCarousel';
import {
  NewsEventsContainer,
  NewsEventsHeader,
  CardsContainer,
  TabsContainer,
  Title
} from './style';
import OpenSans from '../../typography/openSans';

// eslint-disable-next-line no-unused-vars
function Events({ mediaIsPhone, hasTitle, cards, scrollToRef, scrollCount }) {
  //eslint-disable-next-line no-unused-vars
  const [size, setSize] = useState();
  const [tabs, setTabs] = useState([]);
  const [activeTab, setActiveTab] = useState();
  const [currentSlide, setCurrentSlide] = useState(0);


  useEffect(() => {
    const formattedTabs = [...cards].map(card => ({ label: card.title, value: card.title }));
    setActiveTab(formattedTabs[0]);
    setTabs(formattedTabs);
  }, [cards]);

  const myRef = useRef(null);

  useEffect(() => {
    if (hasTitle && scrollToRef === menuRefs.FILE_ARTICLE) {
      myRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [scrollToRef, scrollCount, hasTitle]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize(document.getElementsByClassName('container')[0]?.offsetWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document]);

  const onChangeTab = tab => {
    setActiveTab(tab);
    setCurrentSlide(0);
  };

  const onSlideChange = next => {
    setCurrentSlide(next);
  };

  return (
    <NewsEventsContainer ref={myRef} hasTitle={hasTitle}>
      <Container>
        <NewsEventsHeader>
          {hasTitle && (
          <Title>
            <OpenSans
              type="paragraphTitle"
              configuration={mediaIsPhone ? { fontSize: 20 } : null}
            >
              Resta informato
            </OpenSans>
          </Title>
)}
          <TabsContainer>
            <Tabs
              tabs={[...tabs]}
              onChange={onChangeTab}
              selected={activeTab}
              tabType="primary"
            />
          </TabsContainer>
        </NewsEventsHeader>
        {
          [...cards].map((item) => (
            <>{ activeTab?.value === item.title
            && (
            <CardsContainer currentSlide={currentSlide}>
              <CustomCarousel
                config={
                  mediaIsPhone
                    ? NewsEventsMobileSettings
                    : NewsEventsSettings(size)
                }
                onAfterChange={onSlideChange}
              >
                {item?.cards_event?.map((card, index) => {
                  return (
                    <ContentCard
                      key={`event-card-${index}`}
                      type={detailType.EVENTS}
                      data={card}
                      index={index}
                    />
                  );
                })}
              </CustomCarousel>
            </CardsContainer>
            )
            }
            </>
          ))
        }

      </Container>
    </NewsEventsContainer>
  );
}

Events.propTypes = {
  mediaIsPhone: PropTypes.bool,
  hasTitle: PropTypes.bool,
  cards: PropTypes.array,
  scrollToRef: PropTypes.string,
  scrollCount: PropTypes.number
};

export default connect(state => {
  const { scrollToRef, scrollCount } = state.landingPage;
  return { scrollToRef, scrollCount };
})(withMediaQueries(Events));
