/* eslint-disable no-sequences */
import axios from 'axios';
import { aesEncrypt } from 'utils/crypt';
import routes from 'routes';

export const isLocalHost = window.location.hostname === 'localhost';

const ENV_BASE_URL = {
  DEV: '',
  SIT: 'https://s4b2api.ispfdevelop.com',
  PRE_PROD: '',
  PROD: 'https://api.skills4business.it',

  DEFAULT: 'https://api.skills4business.it'
};

export const BRIGHT_COVE_ACCOUNT = '5660549830001';

export const WEB_SOURCE_BASEURL = {
  LOCAL: 'https://los4b2sit.ispfdevelop.com/',
  DEV: 'https://los4b2sit.ispfdevelop.com/',
  SIT: 'https://los4b2sit.ispfdevelop.com/',
  PRE_PROD: 'https://los4b2sit.ispfdevelop.com/',
  PROD: 'https://los4b2sit.ispfdevelop.com/'
};

export const apiHost =
  ENV_BASE_URL[process.env.REACT_APP_DEPLOY_ENV] || ENV_BASE_URL.DEFAULT;

const ENV_X_API_KEY = {
  DEV: null,
  SIT: 'g1KSFg7H1A9QzGhSASviP8EmXqAsqqGt9iERQo2p',
  PRE_PROD: null,
  PROD: 'jWfdyaIOLf8aCLvYd1HAS1iOb2Ddh3LaaktgDpVK',

  DEFAULT: 'jWfdyaIOLf8aCLvYd1HAS1iOb2Ddh3LaaktgDpVK'
};

export const xApiKey =
  ENV_X_API_KEY[process.env.REACT_APP_DEPLOY_ENV] || ENV_X_API_KEY.DEFAULT;

const proxyUrl = {
  private: '/proxy/private/api/v1/private_proxy',
  public: '/proxy/api/v1/public_proxy'
};

export const ENCRIPT_KEY = {
  LOCAL: 'gJ4OtUtzet3a+lxsyKH/lA==',
  DEV: 'gJ4OtUtzet3a+lxsyKH/lA==',
  SIT: 'gJ4OtUtzet3a+lxsyKH/lA==',
  PROD: 'gJ4OtUtzet3a+lxsyKH/lA=='
};

export const socialPrefix = '/social';
export const baseUrl = '/api/';
export const extendUrl = 'v1/';
export const channel = 'web';

const checkForLocalhostRules = (url = '') => {
  if (isLocalHost) {
    const baseurl = `http://${window.location.hostname}`;
    const port = ':2222';

    return `${baseurl}${port}${url}`;
  }

  return `${apiHost}${url}`;
};

export const axiosApi = axios.create({
  withCredentials: true
});

axiosApi.interceptors.request.use(req => {
  if (
    req?.url?.includes('public') ||
    (req.data &&
      (req?.data?.body?.withXApiKey ||
        req?.data?.withXApiKey ||
        (typeof req?.data?.body === 'string' &&
          req?.data?.body?.includes('withXApiKey')) ||
        (typeof req?.data === 'string' && req?.data?.includes('withXApiKey'))))
  ) {
    req.headers['x-api-key'] = xApiKey;
  }

  return req;
});

axiosApi.interceptors.response.use(
  res => ({
    ...res,
    // eslint-disable-next-line
    data: Object.keys(res.data).reduce(
      (c, k) => ((c[k.toLowerCase()] = res.data[k]), c),
      {}
    )
  }),
  err => {
    //console.log({ ...err });
    if (err?.response?.data?.message === 'Session is expired') {
      window.location.href = routes.sessionExpired.path;
    }
    return Promise.reject(err);
  }
);

const checkUrlForProxy = (url = '', isDrupal = false, isPrivate = false) =>
  checkForLocalhostRules(
    !isDrupal ? url : isPrivate ? proxyUrl.private : proxyUrl.public
  );

const checkBodyForProxy = (
  body,
  isDrupal = false,
  endpoint = '',
  method = 'POST',
  userId,
  withXApiKey = false,
  encryptBody = false
) => {
  return !isDrupal
    ? encryptBody && !(process.env.REACT_APP_DEPLOY_ENV === 'SIT')
      ? { body: aesEncrypt(body), withXApiKey: withXApiKey || undefined }
      : { ...(body || {}), withXApiKey: withXApiKey || undefined }
    : {
        body: body ? JSON.stringify(body) : '',
        method,
        endpoint,
        userId,
        domainUrl: window.location.origin
      };
};

const checkMethod = (method, isDrupal = false) =>
  (isDrupal ? 'POST' : method).toLowerCase();

export function apiCall(
  url,
  method,
  body = null,
  {
    isDrupal = false,
    isPrivate = false,
    userId,
    withXApiKey = false,
    encryptBody = false
  } = {}
) {
  return axiosApi[checkMethod(method, isDrupal)](
    checkUrlForProxy(url, isDrupal, isPrivate),
    checkBodyForProxy(
      body,
      isDrupal,
      url,
      method,
      userId,
      withXApiKey,
      encryptBody
    )
  );
}

export const setCallHeader = (name, value) => {
  axiosApi.defaults.headers.common[name] = value;
};
