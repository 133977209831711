import React, { useEffect, useState } from 'react';
import { CardContainer, CardImage, CardInfoContainer, CardTitle, CardBody, ButtonWrapper } from './style';
import OpenSans from '../../../typography/openSans';
import HtmlRaw from '../../HtmlRaw';
import { Button } from '../../../atoms';

const MobileFileArticleCard = ({ data }) => {
  const [info, setInfo] = useState(data);
  useEffect(() => {
    setInfo(data);
  }, [data]);
  return (
    <CardContainer>
      <CardImage background={info.image} imgPosition={info.image_position}/>
      <CardInfoContainer>
        <CardTitle><OpenSans configuration={{fontSize: 16, fontWeight: 600}}>{info.title}</OpenSans></CardTitle>
        <CardBody>
          <OpenSans configuration={{fontSize: 14, lineHeight: 1.5}}>
            <HtmlRaw
              html={info.body_trimmed || info.body || info.body_2_trimmed || info.body_2 || info.description}
              withEllipsis={info.body_trimmed !== info.body}
            /></OpenSans>
        </CardBody>
        <ButtonWrapper>
            <Button
              text={'Scopri di più'.toUpperCase()}
              height={35}
              fontSize={12}
              type={Button.TYPE.PRIMARY}
              onClick={() => window.open(info.cta_1_url || info.cta_2_url, '_blank')}
            />
        </ButtonWrapper>
      </CardInfoContainer>
    </CardContainer>
  );
};

MobileFileArticleCard.propTypes = {};

export default MobileFileArticleCard;
