import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Label, Input, CustomCheckbox, TextWrapper } from './style';
import OpenSans from '../../typography/openSans';

const Checkbox = ({
  label,
  name,
  value,
  onChange,
  checked,
  disabled,
  rounded
}) => {
  const [check, setCheck] = useState(checked);
  useEffect(() => {
    setCheck(checked);
  }, [checked]);
  const updateCheck = ({ target: { checked: newCheck } = {} }) => {
    setCheck(newCheck);
    if (onChange) onChange(newCheck);
  };
  return (
    <Label>
      <TextWrapper style={{ width: '100%' }}>
        <OpenSans type="checkboxLabel">{label}</OpenSans>
      </TextWrapper>
      <Input
        type="checkbox"
        name={name}
        value={value}
        onChange={updateCheck}
        checked={check}
        disabled={disabled}
      />
      <CustomCheckbox rounded={rounded} />
    </Label>
  );
};

Checkbox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string
  ]),
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  rounded: PropTypes.bool
};

Checkbox.defaultProps = {
  disabled: false
};

export default Checkbox;
