import produce from 'immer';

import {
  LOADER_OPEN,
  LOADER_CLOSE,
} from 'redux/actions';

const initialState = {
  loader: {
    instances: 0,
    isSpinning: false
  }
};

const appReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {

      case LOADER_OPEN: {
        const i = state.loader.instances > 0 ? state.loader.instances + 1 : 1;
        draft.loader.instances = i;
        draft.loader.isSpinning = i > 0;

        break;
      }

      case LOADER_CLOSE: {
        const i = state.loader.instances >= 1 ? state.loader.instances - 1 : 0;
        draft.loader.instances = i;
        draft.loader.isSpinning = i > 0;

        break;
      }

      default:
        return state;
    }
    return draft;
  });

export default appReducer;
