import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'ui/gridSystem';
import { getMenuLabel } from 'utils/menu';
import routes from 'routes';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import { SCROLL_TO } from 'redux/actions/landingPage';
import {
  Award,
  FixedContainer,
  Logo,
  LogoContainer,
  MenuContainer,
  MenuItem,
  MenuList
} from './style';
import logo1x from '../../../assets/img/Skills4ESG_white.png';
import logo2x from '../../../assets/img/Skills4ESG_white.png';
import logo3x from '../../../assets/img/Skills4ESG_white.png';
import OpenSans from '../../../typography/openSans';
import award from '../../../assets/img/Logo_premioMF.png'

const DesktopNavigationMenu = ({ menu, pushUrl, scrollToRef }) => {
  const [listMenu, setListMenu] = useState(menu);
  useEffect(() => {
    setListMenu(menu);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menu]);

  return (
    <FixedContainer>
      <Container>
        <MenuContainer>
          <LogoContainer>
            <Logo
              src={logo1x}
              srcSet={`${logo1x} 300w, ${logo2x} 768w, ${logo3x} 1280w`}
              onClick={() => {
                const isFromNews = window.location.href.includes('news');
                pushUrl(routes.landingPage.path);
                scrollToRef('', isFromNews);
              }}
            />
            <Award src={award} />
          </LogoContainer>
          <MenuList>
            {listMenu.map((item, index) => {
              if (getMenuLabel(item.value))
                return (
                  <MenuItem
                    key={`${item.value}-${index}`}
                    onClick={() => item.onClick()}
                  >
                    <OpenSans
                      type="navigationMenuLink"
                      configuration={{ fontWeight: 300, color: 'white' }}
                    >
                      {getMenuLabel(item.value)}
                    </OpenSans>
                  </MenuItem>
                );
              return null;
            })}
          </MenuList>
        </MenuContainer>
      </Container>
    </FixedContainer>
  );
};

DesktopNavigationMenu.propTypes = {
  menu: PropTypes.array.isRequired,
  pushUrl: PropTypes.func,
  scrollToRef: PropTypes.func
};

export default connect(
  () => ({}),
  dispatch => ({
    pushUrl: url => dispatch(push(url)),
    scrollToRef: (id, isFromNews) =>
      dispatch({ type: SCROLL_TO, id, isFromNews })
  })
)(DesktopNavigationMenu);
