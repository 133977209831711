import styled from 'styled-components';
import { colorOpacity } from 'utils/common';

export const NewsEventsContainer = styled.div`
  width: 100%;
  min-height: ${props => props.hasTitle ? 645 : 545}px;
  padding-top: ${props => props.hasTitle ? 100 : 20}px;
  height: fit-content;
  background-color: ${props => props.theme.colors.middleLightGray};
  @media ${props => props.theme.device.mobile} {
    padding-top: ${props => props.hasTitle ? 50 : 20}px;
    min-height: ${props => props.hasTitle ? 550 : 450}px;
  }
`;

export const NewsEventsHeader = styled.div`
  display: flex;
  flex-direction: column;
  @media ${props => props.theme.device.tablet} {
    margin-bottom: 35px;
  }
`;

export const Title = styled.h2`
  max-width: 100%;
  margin-bottom: 60px;

  @media ${props => props.theme.device.tablet} {
    margin: 20px 0;
  }
`;

export const TabsContainer = styled.div`
  max-width: 100%;
  margin: 0 auto 20px 0;

  @media ${props => props.theme.device.tablet} {
    max-width: 100%;
    margin: 0;
  }
`;

export const CardsContainer = styled.div`
  position: absolute;
  left: 0;
  width: 100%;

  @media ${props => props.theme.device.mobile} {
    flex-direction: column;

    & div.slick-list {
      padding: ${props => (props.currentSlide === 0 ? '0 20px !important' : 0)};
    }
  }

  & div.slick-list {
    max-width: 1280px !important;
    height: 400px;
    margin: 0 auto;
  }
  & div.slick-track {
    height: 400px;
    min-width: 100%;
    transform: translate3d(
      calc(0px - ${props => props.currentSlide} * 275px),
      0px,
      0px
    );
  }
`;

export const Card = styled.div`
  width: 300px;
  height: 300px;
  background-image: url(${props => props.img});
  background-position: center center;
  background-size: cover;
  position: relative;

  &.focus {
    width: 420px;
    z-index: 1;
    top: 64px;
    ${props =>
      props.index === 0
        ? 'left: 40px'
        : props.index === 2
        ? 'right: 40px'
        : 'left: calc(50% - 210px)'};
    position: absolute;

    @media ${props => props.theme.device.mobile} {
      position: relative;
      top: 0;
      left: 0;
      min-width: 100vw;
      align-self: flex-start;
    }
  }
  &.no-focus {
    opacity: 0.3;
    transition: opacity 250ms;
  }
`;

export const ButtonWrapper = styled.div`
  width: 135px;
  height: 55px;
  margin-top: auto;
`;

export const Label = styled.div`
  position: absolute;
  bottom: 50px;
  left: -5px;
  background-color: ${props =>
    colorOpacity(props.theme.colors.primary.base, 0.5)};
  padding: 10px 20px;
  width: 200px;
  height: 60px;
  display: flex;
  align-items: center;

  @media ${props => props.theme.device.mobile} {
    position: relative;
    top: 200px;
    left: -5px;
  }
`;
