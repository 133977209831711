import styled from 'styled-components';

export const ModalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100vh;
  /* height: calc(${window.innerHeight * 0.01 || '1vh'} * 100); */
  @media ${props => props.theme.device.mobile} {
    height: auto;
  }
`;

export const ModalGlobalContainer = styled.div`
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.black};
  border-radius: 6px;
  pointer-events: auto;
  position: relative;
  user-select: none;

  min-height: 459px;
  padding: 26px;

  height: ${props => (props.height ? `${props.height}` : '459px')};
  width: ${props => (props.width ? `${props.width}` : '644px')};
  margin: 0 auto;

  @media ${props => props.theme.device.tablet} {
    width: ${props => props.widthtablet && `${props.widthtablet}px`};
  }

  @media ${props => props.theme.device.mobile} {
    position: fixed;
    height: 100vh;
    max-height: -webkit-fill-available;
    width: 100vw;
    top: 0;
    left: 0;
    border-radius: 0;
    padding: 15px 20px 15px 30px;
  }
`;

export const ModalWrapper2 = styled.div`
  width: 100%;
`;
