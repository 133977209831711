import styled, { css } from 'styled-components';

export const CardContainer = styled.div`
  width: 100%;
  height: fit-content;
  padding: 44px 100px;
  background-image: url(${props => props.background});
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-position: ${props => props.imgPosition || 'center'} center;
  margin-bottom: 20px;

  ${props =>
    props.position !== 'left' &&
    css`
      justify-content: flex-end;
    `};
`;

export const CardInfo = styled.div`
  max-width: 380px;
  padding: 40px 30px 40px 30px;
  background-color: ${props => props.theme.colors.white};
  height: 350px;
  position: relative;
`;

export const CardTitle = styled.div`
  padding-bottom: 6px;
  word-break: break-word;
  color: ${props => props.theme.colors.primary.base};
`;

export const CardBody = styled.div``;

export const TimeText = styled.div`
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;
  margin-bottom: 6px;
`;

export const Icon = styled.span`
  font-size: 30px;
  margin: 20px;
  color: ${props => props.theme.colors.primary.base};
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;

  &.small-icon {
    font-size: 18px;
  }

  &.align-left {
    margin-left: auto;
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 30px;
  max-width: 180px;
`;
