import styled, { css } from 'styled-components';

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  left: 0;
  top: 0;

  ${props =>
    props.checked
    && css`
      ~ span {
        border: solid 2px ${props.theme.colors.primary.darker};
      }

      ~ span:after {
        display: block;
        background: ${props.theme.colors.primary.darker};
      }
    `};
`;

const TextWrapper = styled.div`
  width: 100%;
`;

const CustomRadio = styled.span`
  position: absolute;
  left: 0;
  width: 20px;
  height: 20px;
  background-color: ${props => props.theme.colors.white};
  border: solid 1px ${props => props.theme.colors.darkGray};
  border-radius: 100%;

  &:after {
    content: '';
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    margin: auto;
    border-radius: 50%;
    display: block;
  }
`;

const Label = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  color: ${props => props.theme.colors.greenDark};
  margin: 0;
  padding-left: 35px;
  width: 100%;
  margin-bottom: 5px;
`;

export { Label, Input, CustomRadio, TextWrapper };
