import React from 'react';
import { CustomPrevArrow, CustomNextArrow } from 'ui/atoms/CarouselArrows';
import { breakpoint } from 'ui/theme/partials/device';

export const Settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  arrows: true,
  responsive: [
    {
      breakpoint: breakpoint.laptop,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: breakpoint.tablet,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

export const SliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  autoplay: false,
  autoplaySpeed: 5000,
  pauseOnFocus: false,
  pauseOnHover: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  arrows: true,
  dotsClass: 'slick-dots container',
  responsive: [
  ]
};
export const FinalSliderSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  arrows: true,
  responsive: []
};

export const CollectionsSettings = {
  dots: false,
  infinite: true,
  speed: 250,
  autoplay: true,
  autoplaySpeed: 0,
  slide: 'div',
  cssEase: 'linear',
  slidesToShow: 4.2,
  slidesToScroll: 1,
  initialSlide: -1.8,
  pauseOnFocus: false,
  pauseOnHover: false,
  arrows: false,
  responsive: [
    {
      breakpoint: breakpoint.laptop,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: breakpoint.tablet,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0
      }
    }
  ]
};

export const SDSSettings = {
  dots: false,
  infinite: true,
  className: 'center',
  centerMode: true,
  centerPadding: '100px',
  speed: 1000,
  autoplay: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipe: false,
  initialSlide: 0,
  pauseOnFocus: false,
  pauseOnHover: false,
  arrows: false,
  variableWidth: true,
  responsive: [
    {
      breakpoint: breakpoint.tablet,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0
      }
    }
  ]
};

export const NewsEventsSettings = size => {
  return {
    rows: 1,
    dots: false,
    infinite: false,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    nextArrow: <CustomNextArrow size={size} />,
    prevArrow: <CustomPrevArrow size={size} />,
    responsive: [
      {
        breakpoint: breakpoint.tablet,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0
        }
      },
      {
        breakpoint: breakpoint.mobile,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0
        }
      }
    ]
  };
};

export const NewsEventsMobileSettings = {
  dots: false,
  infinite: false,
  className: 'center',
  centerMode: true,
  speed: 1000,
  autoplay: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  pauseOnFocus: false,
  pauseOnHover: false,
  arrows: false,
  responsive: [
    {
      breakpoint: breakpoint.tablet,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0
      }
    }
  ]
};
